const zetaRequest = function(name: string, args?: any) {
    return new Promise((resolve, reject) => {
        // @ts-ignore
        const zetaObj = window.zeta || zeta;

        if (zetaObj && zetaObj[name]) {
            if (args) {
                zetaObj[name](
                    args,
                    (res: any) => {
                        resolve(res);
                    },
                    (err: any) => {
                        reject(err);
                    }
                );
            } else {
                zetaObj[name](
                    (res: any) => {
                        resolve(res);
                    },
                    (err: any) => {
                        reject(err);
                    }
                );
            }
        } else {
            reject({
                error: name + ' NOT_FOUND',
            });
        }
    });
};

class ZetaBridge {
    // getPhoto(params: any) {
    //     let arg = {
    //         allowMultiplePhotos: params.isMultiple,
    //         useCustomCamera: params.useCustomCamera,
    //         //'sourceType': 'camera',
    //         sourceType: params.sourceType,
    //         returnDataType: '',
    //     };
    //     return zetaRequest('getPhoto', arg);
    // }

    getAuthToken(args: any): Promise<any> {
        return zetaRequest('getAuthToken', args);
    }

    // getUserProfile() {
    //     return zetaRequest('getUserProfile');
    // }

    zetaReady() {
        return new Promise(resolve => {
            const intervalId = setInterval(() => {
                if ((window as any).zeta) {
                    //console.error('success', (window as any).zeta);
                    clearInterval(intervalId);
                    console.error('resolving zeta ready promise');
                    resolve();
                } else {
                    //console.error('error', (window as any).zeta);
                }
            }, 100);
            // @ts-ignore
            // if (window.zeta && typeof zeta === 'undefined') {
            //     //console.error('available', (window as any).zeta);
            //     document.addEventListener('zetaready', () => {
            //         //console.error('zetaready', (window as any).zeta);
            //         clearInterval(intervalId);
            //         resolve();
            //     });
            // } else {
            //     //console.error('not yet available', (window as any).zeta);
            //     clearInterval(intervalId);
            //     resolve();
            // }
        });
    }

    openPageWithNavigation(args: any) {
        return zetaRequest('openPageWithNavigation', args);
    }

    previewFile(args: any) {
        return zetaRequest('previewFile', args);
    }

    setAppTheme(args: any) {
        return zetaRequest('setBackgroundColor', args);
    }

    setAuthData(args: any) {
        return zetaRequest('getAuthData', args);
    }

    reportEvent(args: any) {
        return zetaRequest('reportEvent', args);
    }

    invokeDeeplink(args: any) {
        return zetaRequest('invokeDeeplink', args);
    }

    logoutUser() {
        return zetaRequest('logoutUser', {});
    }
    getUAInfo() {
        return zetaRequest('getUAInfo', {});
    }
}

export default new ZetaBridge();
