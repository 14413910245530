export const FETCH_USER_REWARD_BALANCE = 'fetchUserRewardBalance';
export const FETCH_AWARD_LIST = 'fetchAwardList';
export const FETCH_CATEGORY_LIST = 'fetchCategoryList';
export const FETCH_SALEITEMS_LIST = 'fetchSaleItemsList';
export const FETCH_SALEITEM_BY_ID = 'fetchSaleItemById';
export const FETCH_SUBCATEGORY_LIST = 'fetchSubCategoryList';
export const FETCH_SALEITEMS_BY_CATEGORY_ID = 'fetchSaleItemsByCategoryId';
export const FETCH_ORDER_RESOURCE_FOR_USER = 'fetchOrderResourceForUser';
export const FETCH_RESOURCE_DETAILS_BY_ID = 'fetchResourceDetailsById';
export const FETCH_BADGE_BY_ID = 'fetchBadgeById';
export const ORDER_INITIATE = 'orderInitiate';
export const ORDER_INITIATE_PAYMENT = 'orderInitiatePayment';
export const ORDER_PLACE = 'orderPlace';
export const ORDER_CONFIRM = 'orderConfirm';
export const EVALUATE_BASKET = 'evaluateBasket';

export const IS_BASKET_UPDATE_POSSIBLE = 'isBasketUpdatePossible';
export const FETCH_POINTS_HISTORY = 'fetchPointsHistory';
export const FETCH_NOMINATIONS_APPROVAL_PENDING_LIST =
    'fetchNominationsApprovalPendingList';
export const PROCESS_NOMINATION_AWARD = 'processNominationAward';

export const FETCH_SALEITEMS_FILTERS = 'fetchSaleItemsFilters';

export const GET_AWARD_DETAILS = 'getAwardDetails';
