import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { RootState } from '@/store/types';
import actions from './feed.actions';
import getters from './feed.getters';
import mutations from './feed.mutations';
import state from './feed.state';

import { FeedState } from './feed.types';

const module: Module<FeedState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export const FEED_STORE = 'feed';

export const FeedModule = namespace(FEED_STORE);

export default module;
