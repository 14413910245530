import { AxiosInstance, AxiosPromise } from 'axios';
import Axios from 'axios';

import {
    createAxiosInstance,
    ExtendedAxiosRequestConfig,
} from '@zeta/http-client';
import { setupInterceptorsTo } from '@/commons/interceptor';

// const baseURL: any = {
//     STAGE: 'https://marketplace-spotlight.mum1-stage.zeta.in/marketplace/1.0',
//     PREPROD: 'https://marketplace-spotlight.mum1-pp.zeta.in/marketplace/1.0',
//     PROD: 'https://marketplace-spotlight.mum1.zeta.in/marketplace/1.0',
//     PROD_UK: 'https://sb1-god-spotlight.lon1.zetaapps.in/marketplace/1.0',
//     SWAGGER:
//         'https://virtserver.swaggerhub.com/z3457/spotlight-marketplace/1.0.0-SNAPSHOT',
// };

class PaymentService {
    private axios: AxiosInstance;

    constructor(config: ExtendedAxiosRequestConfig) {
        this.axios = createAxiosInstance(config);
        setupInterceptorsTo(this.axios);
    }
    private get servicePath() {
        return ``;
    }

    public orderInitiate(payload: any) {
        return this.axios.post(
            `${this.servicePath}/user/v1/order/initiate`,
            payload
        );
    }

    public orderPlace({ ...payload }: any) {
        const { marketplaceProviderId, marketplaceId } = payload;
        delete payload.marketplaceProviderId;
        // delete payload.marketplaceId
        return this.axios.post(
            `${this.servicePath}/provider/${marketplaceProviderId}/marketplace/${marketplaceId}/user/v1/order/place`,
            payload
        );
    }

    public orderInitiatePayment(payload: any) {
        return this.axios.post(
            `${this.servicePath}/user/v1/order/initiatePayment`,
            payload
        );
    }

    public orderConfirm(id: string, payload: any) {
        return this.axios.post(
            `${this.servicePath}/user/v1/order/${id}/confirm`,
            payload
        );
    }

    public getUserProfile(params: any) {
        return this.axios.get(
            `${this.servicePath}/esp/${params.espId}/marketplace/${params.marketplaceId}/user/v1/profile`
        );
    }

    public setUserProfile(payload: any, params: any) {
        return this.axios.post(
            `${this.servicePath}/esp/${params.espId}/marketplace/${params.marketplaceId}/user/v1/profile`,
            payload
        );
    }

    public getUserOrderStatistics(params: any) {
        return this.axios.get(
            `${this.servicePath}/esp/${params.espId}/marketplace/${params.marketplaceId}/user/v1/order/statistics`
        );
    }
}

const config = {
    baseURL: __APP__.MARKETPLACE.BASE_URL,
    resolveAuthToken: () => {
        const token: string = localStorage.getItem('@zeta::authToken') || '';
        return token;
    },
};

// console.log('baseURL[__ENV__] ==> ', baseURL, __ENV__, baseURL[__ENV__])
export default new PaymentService(config);
