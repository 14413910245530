import { RouteConfig } from 'vue-router';
import { MODULES, pages } from '@/commons/constants';

const Profile = () =>
    import(
        /* webpackChunkName: "ProfilePage" */ '@/modules/profile/Profile.vue'
    );

const ProfileLandingPage = () =>
    import(
        /* webpackChunkName: "ProfileLandingPage" */ '@/modules/profile/pages/UserProfilePage.vue'
    );

const AddBillingAddressPage = () =>
    import(
        /* webpackChunkName: "AddBillingAddressPage" */ '@/modules/profile/pages/AddBillingAddressPage.vue'
    );

const routes: RouteConfig[] = [
    {
        path: MODULES.PROFILE.path,
        component: Profile,
        children: [
            {
                path: '',
                name: 'profilelanding',
                component: ProfileLandingPage,
            },
            {
                path: 'add-billing-address',
                name: 'addBillingAddress',
                component: AddBillingAddressPage,
                props: true,
            },
        ],
    },
];

export default routes;
