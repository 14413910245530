import { AxiosInstance } from 'axios';
// import { getEnv } from '@/commons/Utils';

import {
    createAxiosInstance,
    ExtendedAxiosRequestConfig,
} from '@zeta/http-client';

class ZetaSSCService {
    private axios: AxiosInstance;

    constructor(config: ExtendedAxiosRequestConfig) {
        this.axios = createAxiosInstance(config);
    }
    private get servicePath() {
        return `/zeta.in/ssc/1.0`;
    }

    public resolveEmployee(params: any = {}) {
        return this.axios.get(`${this.servicePath}/getEmployeeProfile`, {
            params,
        });
    }

    public getEmployee(params: any) {
        return this.axios.get(`${this.servicePath}/getBeneficiaryDetails`, {
            params,
        });
    }

    public setEmployee(payload: any) {
        return this.axios.post(
            `${this.servicePath}/updateEmployeeAttributes`,
            {
                ...payload,
            },
            { ...payload }
        );
    }

    public getEmployeesList(params: any) {
        return this.axios.get(`${this.servicePath}/getEmployeesForGroupIDs`, {
            params,
        });
    }

    public getCompanyAttributes(params: any) {
        return this.axios.get(
            `${this.servicePath}/getCompanyAttributes?espID=${params.espID}&corporateID=${params.corpID}&companyID=${params.compID}`,
            {
                ...params,
            }
        );
    }
}

const config = {
    baseURL: __APP__.ZETA_API_BASE_URL,
    resolveAuthToken: () => {
        return localStorage.getItem('@zeta::authToken') || '';
    },
};
export default new ZetaSSCService(config);
