
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import { VALID_TILL_KEY, AuthStore } from '@zeta/authentication';

// components
import Layout from '@/components/Layout.vue';
import MobileLayout from '@/components/MobileLayout.vue';
import ErrorPage from '@/components/ErrorPage.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
// store
import { IS_MOBILE_VIEW } from '@/store/getters.type';
import FullPageShimmer from '@/components/FullPageShimmer.vue';
import {
    FETCH_USER_DETAIL,
    FETCH_PROGRAM_LIST,
    FETCH_COMPANY_ATTRIBUTES,
    LOG_OUT,
    FETCH_ACCOUNT_HOLDER_DETAILS,
    FETCH_MARKETPLACE_PROVIDER,
} from '@/store/actions.types';
import { Theme } from './store/types';

// utils
import { EventBus, Events } from '@/commons/eventBus';
import { refreshToken } from '@/utils/auth';
import initPendo from '@/commons/pendo';
import { getDeviceType } from '@/commons/Utils';
import updateTheme from '@/utils/theme';
import store from '@/store';
import onVisibilityChange from '@/utils/visibility';
import { isLoggedIn } from '@/utils/auth';
import zetaBridge from './services/zetaBridge';
import { IdleTimeoutManager } from '@/commons/idleTimeoutManager';
const authStore: any = AuthStore;
import initFirebase from '@/commons/firebase';

@Component({
    components: {
        Layout,
        MobileLayout,
        ErrorPage,
        LoadingComponent,
        FullPageShimmer,
    },
})
export default class Wrapper extends Vue {
    @Action(FETCH_USER_DETAIL) private fetchUserDetail: any;

    @Action(FETCH_ACCOUNT_HOLDER_DETAILS)
    private fetchAccountHolderDetails: any;

    @Action(FETCH_PROGRAM_LIST) private fetchProgramList: any;
    /**
     * Action to fetch company attrobutes from corp core, used mainly for themes, logo.
     */
    @Action(FETCH_COMPANY_ATTRIBUTES) private fetchCompanyAttributes: any;

    @Action(LOG_OUT) private logout: any;

    @Prop({ default: false }) private isMounted!: boolean;

    @Getter(IS_MOBILE_VIEW) private isMobileView!: boolean;

    @Action(FETCH_MARKETPLACE_PROVIDER)
    private fetchMarketplaceProvider: any;
    @State('deviceType') private deviceType!: string;
    private hasError = false;
    private loading = true;
    private accountHolderId: any;
    private isApp = false;
    private setDefaultTheme(): void {
        return this.fetchCompanyAttributes().then((res: any) => {
            const themeColours: any = {};
            if (!res.themes) return;
            let defaultTheme: any = res.themes.filter((theme: Theme) => {
                return theme.isDefault;
            })[0];
            if (!defaultTheme) {
                defaultTheme = res.themes[0];
            }
            Object.keys(defaultTheme.colours).forEach((color: string) => {
                themeColours[color] = `#${defaultTheme.colours[color]}`;
            });

            if (this.isApp) {
                zetaBridge.setAppTheme({ hexCode: themeColours.primary });
            }
            updateTheme(themeColours);
        });
    }

    private setFreshDeskParams() {
        const {
            employeeID,
            companyID,
            corporateID,
            email,
            name,
        } = store.state.userDetail;

        const w: any = window;
        if (w.FreshworksWidget) {
            w.FreshworksWidget('prefill', 'ticketForm', {
                email: email,
                name: name,
                custom_fields: {
                    cf_ah_id: this.accountHolderId
                        ? String(this.accountHolderId)
                        : '',
                    cf_unique_id: String(employeeID),
                },
            });
        }
    }
    private forceLogout() {
        window.localStorage.clear();
        window.location.href = '/auth/login';
    }
    private setIdleTimer() {
        const manager = new IdleTimeoutManager({
            timeout: 30 * 60, //expired after 30 min
            key: '_idleTimeout',
            //events: ["mousemove", "scroll", "keydown"],
            onExpired: (time: any) => {
                console.log(`Expired at ${time}`);
                this.logout();
            },
            eventTimeout: 500, //miliseconds
        });
    }
    private async setupAuthTokeAndnRefreshLogic() {
        if (!this.isApp) {
            const expiryInMs: any =
                authStore.getItem(VALID_TILL_KEY) - Date.now();
            const FIVE_MINUTES = 5 * 60 * 1000;
            console.log(`expiring in ${expiryInMs / (1000 * 60)} minutes`);

            if (expiryInMs < FIVE_MINUTES) {
                console.log('about to expire in 5 mins');
                await refreshToken();
            }
            this.setIdleTimer();
            setInterval(() => {
                //Not using set timeout as if the api call fails once at the moment, it will not retry

                const expiryInMs: any =
                    authStore.getItem(VALID_TILL_KEY) - Date.now();
                if (expiryInMs < FIVE_MINUTES) {
                    console.log('#refreshing token');
                    refreshToken().catch(error => console.log('error', error));
                }
            }, FIVE_MINUTES);
            onVisibilityChange((hidden: any) => {
                if (!hidden && !isLoggedIn()) {
                    this.forceLogout();
                }
            });
        }
    }

    private async created(): Promise<any> {
        try {
            this.isApp = this.deviceType === 'APP';
            initFirebase({ isApp: this.isApp });
            await this.setupAuthTokeAndnRefreshLogic();
            await this.fetchUserDetail();
            setTimeout(() => {
                initPendo();
            }, 0);
            await Promise.all([
                this.setDefaultTheme(),
                this.fetchMarketplaceProvider(),
                this.fetchAccountHolderDetails().then((response: any) => {
                    this.accountHolderId = response.accountHolderId;
                    this.setFreshDeskParams();
                }),
            ]);
            this.loading = false;

            EventBus.$on(Events.logout, this.logout);
        } catch (e) {
            console.log('SETUP ERROR', e.message);
            if (e.message && String(e.message).indexOf('#LOGOUT') > -1) {
                if (!this.isApp) {
                    this.logout();
                } else {
                    //   window.location.assign('spk://logout?immediateLogout=true');
                    console.log(e.message);
                }

                return;
            }
            this.loading = false;
            this.hasError = true;
        }
    }
}
