export const GET_PROGRAM_CURRENCY = 'getProgramCurrency';
export const GET_PROGRAM_TO_BADGE_LIST = 'getProgramToBadgeList';

export const GET_CATEGORY_LIST = 'getCategoryList';
export const GET_ORDER_DETAILS = 'getOrderDetails';
export const GET_BASKET = 'getBasket';
export const GET_IS_POSSIBLE_TO_ADD_VOUCHER = 'getIsPossibleToAddVoucher';
export const GET_RECIEVED_AWARD_BY_USER_ID = 'getRecievedAwardByUserId';
export const GET_NOMINATIONS_APPROVAL_PENDING_LIST =
    'nominationsApprovalPendingList';
export const GET_NOMINATIONS_APPROVAL_PENDING_BY_ID =
    'getNominationsApprovalPendingById';
export const GET_NOMINATIONS_APPROVAL_LIST = 'getNominationsApprovalList';
