import { RouteConfig } from 'vue-router';
import { MODULES, pages } from '@/commons/constants';

const More = () =>
    import(/* webpackChunkName: "MorePage" */ '@/modules/more/More.vue');

const MoreLandingPage = () =>
    import(
        /* webpackChunkName: "MoreLandingPage" */ '@/modules/more/pages/MoreLandingPage.vue'
    );

const routes: RouteConfig[] = [
    {
        path: '/more',
        component: More,
        children: [
            {
                path: '',
                name: 'morelanding',
                component: MoreLandingPage,
            },
        ],
    },
];

export default routes;
