/* eslint-disable no-console */
import {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from 'axios';
import { ToastProgrammatic as Toast } from 'buefy';

enum USER_STATUS {
    PAUSED = 'USER.NOT.ACTIVE',
}

function openErrorToast(msg: string): void {
    Toast.open({
        duration: 5000,
        message: `<span class="icon mdi mdi-close-circle is-size-1 mr-2"> </span> <span style="position:relative; top: -4px;"> ${msg} </span>`,
        position: 'is-bottom',
        type: 'is-danger',
    });
}

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    //  console.info(`[request] [${JSON.stringify(config)}]`);
    return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    //  console.error(`[request error] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
    // console.info(`[response] [${JSON.stringify(response)}]`);
    return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    //  console.error(`[response error] [${JSON.stringify(error)}]`);
    const { code = '', message = '' } = error?.response?.data || undefined;
    if (code === USER_STATUS.PAUSED) {
        openErrorToast(message);
        (error?.response?.data).config = {
            errorHandled: true,
        };
    }
    return Promise.reject(error);
};

export function setupInterceptorsTo(
    axiosInstance: AxiosInstance
): AxiosInstance {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
}
