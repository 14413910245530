import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DiscountsState } from './discounts.types';
import {
    FETCH_CATEGORY_LIST,
    FETCH_SALEITEMS_LIST,
    FETCH_SALEITEM_BY_ID,
    FETCH_SUBCATEGORY_LIST,
    FETCH_SALEITEMS_BY_CATEGORY_ID,
    FETCH_ORDER_RESOURCE_FOR_USER,
    FETCH_RESOURCE_DETAILS_BY_ID,
    ORDER_INITIATE,
    ORDER_INITIATE_PAYMENT,
    ORDER_CONFIRM,
    EVALUATE_BASKET,
    IS_BASKET_UPDATE_POSSIBLE,
    FETCH_SALEITEMS_FILTERS,
    FETCH_ORDERS,
    FETCH_ORDER_DETAILS,
    FETCH_REFUND_DETAILS,
} from './discounts.actions.types';
import DiscountsService from '@/services/discounts';
import PaymentsService from '@/services/payments';
import { v4 as uuidv4 } from 'uuid';
const actions: ActionTree<DiscountsState, RootState> = {
    async [FETCH_CATEGORY_LIST]({ rootState }, params) {
        const res = await DiscountsService.fetchCategories({ params });
        return res.data.data;
    },
    async [FETCH_SALEITEMS_LIST]({ rootState }, { payload, params }) {
        const res = await DiscountsService.fetchSaleItems(payload.queryString, {
            params,
        });
        return res.data.data;
    },
    async [FETCH_SALEITEMS_FILTERS](
        { rootState },
        { params, queryString } = {}
    ) {
        const res = await DiscountsService.fetchSaleItemFilters({
            params,
            queryString,
        });
        return res.data.data;
    },

    async [FETCH_SALEITEM_BY_ID]({ rootState }, id: number) {
        const res = await DiscountsService.fetchSaleItemById(id);
        return res.data.data;
    },
    async [FETCH_SUBCATEGORY_LIST]({ rootState }, id: number) {
        const res = await DiscountsService.fetchSubCategoriesById(id);
        return res.data.data;
    },
    async [FETCH_SALEITEMS_BY_CATEGORY_ID]({ rootState }, id: number) {
        const res = await DiscountsService.fetchSaleItemsByCategoryId(id);
        return res.data.data;
    },
    async [FETCH_ORDER_RESOURCE_FOR_USER]({ rootState, state }, payload: any) {
        const res = await DiscountsService.fetchOrderResourceForUser(
            rootState.marketplaceProvider.providerId,
            rootState.marketplaceProvider.defaultMarketPlace.id,
            payload.params
        );
        return res.data;
    },
    async [FETCH_RESOURCE_DETAILS_BY_ID]({ rootState }, payload: any) {
        const res = await DiscountsService.fetchResourceDetailsById(
            payload.orderId,
            payload.orderResourceId,
            payload.orderItemId
        );
        return res.data.data;
    },
    async [ORDER_INITIATE]({ rootState }, payload: any) {
        payload.idempotencyKey = uuidv4();
        const res = await PaymentsService.orderInitiate(payload);
        return res.data;
    },
    async [ORDER_INITIATE_PAYMENT]({ rootState }, payload: any) {
        payload.paymentRequestID = uuidv4();
        const res = await PaymentsService.orderInitiatePayment(payload);
        return res.data;
    },
    async [ORDER_CONFIRM]({ rootState }, payload: any) {
        const orderID = payload.orderID;
        payload.confirmOrderRequestId = uuidv4();
        const res = await PaymentsService.orderConfirm(orderID, payload);
        return res.data;
    },
    async [EVALUATE_BASKET]({ rootState, state }, payload: any) {
        payload.requestId = uuidv4();
        if (payload.items && payload.items.length) {
            payload.espId = rootState.marketplaceProvider.providerId;
            payload.marketplaceId =
                rootState.marketplaceProvider.defaultMarketPlace.id;
            payload.items.forEach((el: any) => {
                el.denominationCurrencyId = el.denominationCurrency.id;
                el.status = 'ENABLED';
            });
            const res = await DiscountsService.evaluateBasket(payload);
            return res.data.data;
        } else {
            return payload;
        }
    },

    async [IS_BASKET_UPDATE_POSSIBLE]({ rootState, state }, payload: any) {
        payload.requestId = uuidv4();
        payload.espId = rootState.marketplaceProvider.providerId;
        payload.marketplaceId =
            rootState.marketplaceProvider.defaultMarketPlace.id;

        payload.basketItems.forEach((el: any) => {
            el.denominationCurrencyId = el.denominationCurrency.id;
        });
        payload.updateItems.forEach((el: any) => {
            el.denominationCurrencyId = el.denominationCurrency.id;
        });

        const res = await DiscountsService.isBasketUpdatePossible(payload);
        return res.data;
    },
    async [FETCH_ORDERS]({ rootState, state }, params) {
        const res = await DiscountsService.fetchOrders(
            {
                espId: rootState.marketplaceProvider.providerId,
                marketplaceId:
                    rootState.marketplaceProvider.defaultMarketPlace.id,
            },
            params
        );
        return res.data.data;
    },
    async [FETCH_ORDER_DETAILS]({ rootState, state }, orderId) {
        const res = await DiscountsService.fetchOrderDetails(
            {
                espId: rootState.marketplaceProvider.providerId,
                marketplaceId:
                    rootState.marketplaceProvider.defaultMarketPlace.id,
            },
            orderId
        );
        return res.data.data;
    },
    async [FETCH_REFUND_DETAILS]({ rootState, state }, paymentId) {
        const res = await DiscountsService.fetchRefundDetails(
            {
                espId: rootState.marketplaceProvider.providerId,
                marketplaceId:
                    rootState.marketplaceProvider.defaultMarketPlace.id,
            },
            { paymentId }
        );
        return res.data.data;
    },
};
export default actions;
