import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import setup from './setup';
import './scss/main.scss';
import { getURLParameter } from '@zeta/utils';
import { getTokenFromCode } from '@/utils/auth';
// import { dummyUsers } from '@/commons/constants';
// import { getURLParameter } from '@zeta/utils';
// import {
//     AuthStore,
// } from '@zeta/authentication';
import { getDeviceType } from './commons/Utils';
import zetaBridge from './services/zetaBridge';
import authenticationStore from '@zeta/authentication/lib/storage';
import { AUTH_TOKEN_KEY } from '@zeta/authentication';
import WebStorage from '@/commons/web.localStorage.ts';

//import { FETCH_USER_DETAIL } from "@/store/actions.types";

if (window.location.hash.indexOf('#/auth/login') > -1) {
    //Remove this block when app has changed its url
    const WINDOW_HASH = window.location.hash;
    const queryString =
        WINDOW_HASH.indexOf('?') > -1
            ? WINDOW_HASH.substr(WINDOW_HASH.indexOf('?'))
            : '';
    history.replaceState('', '', '/auth/login' + queryString);
} else if (window.location.hash.indexOf('#/discounts/order-summary') > -1) {
    //Remove this block when app has changed its url
    const WINDOW_HASH = window.location.hash;
    const queryString =
        WINDOW_HASH.indexOf('?') > -1
            ? WINDOW_HASH.substr(WINDOW_HASH.indexOf('?'))
            : '';
    history.replaceState('', '', '/discounts/order-summary' + queryString);
} else if (window.location.hash.indexOf('#/discounts/order-history') > -1) {
    //Remove this block when app has changed its url
    const WINDOW_HASH = window.location.hash;
    const queryString =
        WINDOW_HASH.indexOf('?') > -1
            ? WINDOW_HASH.substr(WINDOW_HASH.indexOf('?'))
            : '';
    history.replaceState('', '', '/discounts/order-history' + queryString);
}

Vue.config.productionTip = true;
Vue.config.devtools = true;

// Bootstrap app after user role/details are fetched
setup();

const deviceType = getDeviceType();
const isAPP = deviceType === 'APP';
console.log('version check');
async function initApp(vm: any) {
    vm.$mount('#app');
}

function renderApp() {
    const vm = new Vue({
        router,
        store,
        render: h => h(App),
    });
    initApp(vm);
}
if (isAPP) {
    console.log('======isAPP check passed=====', window.location.href);
    zetaBridge.zetaReady().then(async () => {
        zetaBridge.getUAInfo().then(async (device: any) => {
            const os = device.os || device.uaInfo.os;
            console.log('deviceType==>', os, device);
            localStorage.clear();
            WebStorage.setItem(
                'deviceType',
                os == 'ANDROID' ? 'android' : 'ios'
            );
            if (window.location.href.indexOf('/auth/') < 0) {
                try {
                    const res = await zetaBridge.getAuthToken({});
                    console.error('res app getAuthtoken resp', res);
                    const authToken = res.authToken;
                    authenticationStore.setItem(AUTH_TOKEN_KEY, authToken);
                    renderApp();
                } catch (e) {
                    console.error('#Error in zeta bridge', e);
                }
            } else {
                console.log('======login page detected=====');

                try {
                    const res = await zetaBridge.getAuthToken({});
                    console.error('res app getAuthtoken resp', res);
                    const authToken = res.authToken;
                    //  authenticationStore.setItem(AUTH_TOKEN_KEY, authToken);
                    const callbackUrl = getURLParameter('_callbackUrl');
                    console.log(
                        '====== authToken found - user is logged in =====',
                        authToken
                    );
                    console.log('invoking deep link', callbackUrl);
                    if (callbackUrl) {
                        window.location.assign(callbackUrl);
                    }
                } catch (e) {
                    console.error('#Error in zeta bridge', e);
                    renderApp();
                }
                //  }
            }
        });
    });
} else {
    console.log('======isAPP check failed=====', window.location.href);
    const code = getURLParameter('code');
    if (code) {
        // not putting this in context of vue, as this is once a while thing to touch
        getTokenFromCode(code)
            .then(() => {
                const hash = window.location.hash;
                window.history.replaceState(
                    null,
                    'Spotlight front office',
                    window.location.pathname
                );
                window.location.hash = hash;
                renderApp();
            })
            .catch(() => {
                console.log('App level error');
            });
    } else {
        renderApp();
    }
}
