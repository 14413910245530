const CurrenyConfig: any = Object.freeze({
    INR: {
        symbol: '₹',
        denominationFactor: 100,
        fixedValue: 2,
        validator: (val: any) => {
            return /^\d+\.?(?:\d{1,2})?$/.test(val);
        },
        icon: 'currency-inr',
    },
    USD: {
        symbol: '$',
        denominationFactor: 100,
        fixedValue: 2,
        validator: (val: any) => {
            return /^\d+\.?(?:\d{1,2})?$/.test(val);
        },
        icon: 'currency-usd',
        isDisabled: true,
    },
    Pound: {
        symbol: '£',
        denominationFactor: 100,
        fixedValue: 2,
        validator: (val: any) => {
            return /^\d+\.?(?:\d{1,2})?$/.test(val);
        },
        icon: 'currency-gbp',
    },
});
export default CurrenyConfig;
