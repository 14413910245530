import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { BenefitState } from './benefits.types';
import {
    FETCH_ACTIVE_BENEFITS,
    FETCH_SETUP_BENEFITS,
} from './benefits.actions.types';

import { activeBenefits, setupBenefits } from './dummyData';

const actions: ActionTree<BenefitState, RootState> = {
    // async [FETCH_ACTIVE_BENEFITS]({ rootState }) {
    //   return new Promise((resolve) => {
    //     setTimeout(() => {
    //       resolve({
    //         ...activeBenefits
    //       });
    //     }, 500);
    //   });
    // },
    // async [FETCH_SETUP_BENEFITS]({ rootState }) {
    //   return new Promise((resolve) => {
    //     setTimeout(() => {
    //       resolve({
    //         ...setupBenefits
    //       });
    //     }, 500);
    //   });
    // },
};

export default actions;
