import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { FeedState } from './feed.types';
import {
    FETCH_FEED_LIST,
    FETCH_BADGE_BY_ID,
    CREATE_AWARD,
    FETCH_EMPLOYEE_LIST,
    FETCH_SPECIFIC_FEED_ITEMS,
    FETCH_REACTIONS,
    POST_REACTIONS,
    UPDATE_REACTION,
    FETCH_COMMENTS,
    POST_COMMENTS,
    DELETE_COMMENT,
    GET_AWARD_DETAILS,
    GET_POST_COMPONENTS,
    DELETE_REACTION,
    GET_REWARD_CARDS,
} from './feed.actions.types';

import RecognitionService from '@/services/recognition';
import FeedService from '@/services/feed';
import zetaSSC from '@/services/zetaSSC';
const actions: ActionTree<FeedState, RootState> = {
    async [FETCH_FEED_LIST]({ rootState }, params) {
        const { tenantID } = rootState.userCorpDetail;
        const { companyID } = rootState.userCorpDetail;
        const { corpId } = rootState.userCorpDetail;
        const subTenantId = corpId + '_' + companyID;
        return new Promise(resolve => {
            FeedService.fetchPosts(
                {
                    tenantId: tenantID,
                    subTenantId: subTenantId,
                },
                { ...params }
            ).then(
                (resp: any) => {
                    resolve(resp.data);
                },
                (err: any) => {
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [FETCH_BADGE_BY_ID]({ rootState }, badgeId: any) {
        return new Promise(resolve => {
            RecognitionService.fetchBadgeDetail(
                rootState.userCorpDetail,
                badgeId
            ).then(
                (resp: any) => {
                    resolve(resp.data);
                },
                (err: any) => {
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [CREATE_AWARD]({ rootState }, payload: any) {
        return new Promise(resolve => {
            RecognitionService.createAward(
                rootState.userCorpDetail,
                payload
            ).then(
                (resp: any) => {
                    resolve(resp.data);
                },
                (err: any) => {
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_EMPLOYEE_LIST]({ rootState }, params: any) {
        return new Promise(resolve => {
            const {
                espID: espID,
                corpID: corporateID,
                companyID: companyID,
            } = rootState.userCorpDetail;
            zetaSSC
                .getEmployeesList({
                    espID,
                    corporateID,
                    companyID,
                    groupIDList: params.groupIDList,
                    pageNumber: params.pageNumber || 0,
                    pageSize: 25,
                    sortBy: params.sortBy || 'CREATE_DATE',
                    sortOrder: params.sortOrder || 'ASC',
                    state: params.state,
                    searchTerm: params.searchTerm ? params.searchTerm : '',
                    groupStatus: params.groupStatus || '',
                })
                .then(
                    (resp: any) => {
                        resp.data.employees = resp.data.employeeDetails.employees.filter(
                            (emp: any) => {
                                return (
                                    emp.employeeID !=
                                    rootState.userCorpDetail.employeeID
                                );
                            }
                        );
                        resolve(resp.data);
                    },
                    err => {
                        //   EventBus.$emit(Events.hideLoader);
                        resolve({
                            isError: true,
                            ...err.toJSON(),
                        });
                    }
                );
        });
    },
    async [FETCH_SPECIFIC_FEED_ITEMS]({ rootState }, params) {
        const { tenantId } = rootState.userCorpDetail;
        const { companyID } = rootState.userCorpDetail;
        const { corpId } = rootState.userCorpDetail;
        const subTenantId = corpId + '_' + companyID;

        return new Promise(resolve => {
            FeedService.fetchSinglePost({
                tenantId: tenantId,
                subTenantId: subTenantId,

                ...params,
            }).then(
                (resp: any) => {
                    resolve(resp.data);
                },
                (err: any) => {
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [FETCH_REACTIONS]({ rootState }, params: any) {
        const { tenantId } = rootState.userCorpDetail;
        const { companyID } = rootState.userCorpDetail;
        const { corpId } = rootState.userCorpDetail;
        const subTenantId = corpId + '_' + companyID;

        return new Promise(resolve => {
            FeedService.getReactions({
                tenantId: tenantId,
                postId: params.postId,
                subTenantId: subTenantId,
            }).then(
                (resp: any) => {
                    resolve(resp.data);
                },
                (err: any) => {
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [POST_REACTIONS]({ rootState }, params) {
        const { tenantId } = rootState.userCorpDetail;
        const { companyID } = rootState.userCorpDetail;
        const { corpId } = rootState.userCorpDetail;
        const subTenantId = corpId + '_' + companyID;

        return new Promise(resolve => {
            FeedService.postReaction(
                {
                    tenantId: tenantId,
                    subTenantId: subTenantId,

                    ...params,
                },
                params
            ).then(
                (resp: any) => {
                    resolve(resp.data);
                },
                (err: any) => {
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [UPDATE_REACTION]({ rootState }, params) {
        const { tenantId } = rootState.userCorpDetail;
        const { companyID } = rootState.userCorpDetail;
        const { corpId } = rootState.userCorpDetail;
        const subTenantId = corpId + '_' + companyID;

        return new Promise(resolve => {
            FeedService.updateReaction(
                {
                    tenantId: tenantId,
                    subTenantId: subTenantId,

                    ...params,
                },
                params
            ).then(
                (resp: any) => {
                    resolve(resp.data);
                },
                (err: any) => {
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [DELETE_REACTION]({ rootState }, params) {
        const { tenantId } = rootState.userCorpDetail;
        const { companyID } = rootState.userCorpDetail;
        const { corpId } = rootState.userCorpDetail;
        const subTenantId = corpId + '_' + companyID;

        return new Promise(resolve => {
            FeedService.deleteReaction(
                {
                    tenantId: tenantId,
                    subTenantId: subTenantId,

                    ...params,
                },
                params
            ).then(
                (resp: any) => {
                    resolve(resp.data);
                },
                (err: any) => {
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [FETCH_COMMENTS]({ rootState }, params) {
        const { tenantId } = rootState.userCorpDetail;
        const { companyID } = rootState.userCorpDetail;
        const { corpId } = rootState.userCorpDetail;
        const subTenantId = corpId + '_' + companyID;

        return new Promise(resolve => {
            FeedService.getComments(
                {
                    tenantId: tenantId,
                    postId: params.postId,
                    subTenantId: subTenantId,
                },
                {
                    pageIndex: params.pageIndex,
                    pageSize: params.pageSize,
                }
            ).then(
                (resp: any) => {
                    resolve(resp.data);
                },
                (err: any) => {
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [GET_POST_COMPONENTS]({ rootState }, params) {
        const { tenantId } = rootState.userCorpDetail;
        const { companyID } = rootState.userCorpDetail;
        const { corpId } = rootState.userCorpDetail;
        const subTenantId = corpId + '_' + companyID;
        return new Promise(resolve => {
            FeedService.getPostComponents(
                {
                    tenantId: tenantId,
                    postId: params.postId,
                    subTenantId: subTenantId,
                },
                {
                    pageIndex: params.pageIndex,
                    pageSize: params.pageSize,
                }
            ).then(
                (resp: any) => {
                    resolve(resp.data);
                },
                (err: any) => {
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [POST_COMMENTS]({ rootState }, params: any) {
        const { tenantId } = rootState.userCorpDetail;
        const { companyID } = rootState.userCorpDetail;
        const { corpId } = rootState.userCorpDetail;
        const subTenantId = corpId + '_' + companyID;

        return new Promise(resolve => {
            FeedService.postComment(
                {
                    tenantId: tenantId,
                    subTenantId: subTenantId,

                    ...params,
                },
                {
                    commenterAccountId: params.commenterAccountId,
                    commenterAccountName: params.commenterAccountName,
                    body: params.body,
                    imageAssets: params.imageAssets,
                }
            ).then(
                (resp: any) => {
                    resolve(resp.data);
                },
                (err: any) => {
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [DELETE_COMMENT]({ rootState }, params: any) {
        const { tenantId } = rootState.userCorpDetail;
        const { companyID } = rootState.userCorpDetail;
        const { corpId } = rootState.userCorpDetail;
        const subTenantId = corpId + '_' + companyID;

        return new Promise(resolve => {
            FeedService.deleteComment({
                tenantId: tenantId,
                subTenantId: subTenantId,
                ...params,
            }).then(
                (resp: any) => {
                    resolve(resp.data);
                },
                (err: any) => {
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [GET_AWARD_DETAILS]({ rootState }, awardId: string) {
        const res = await RecognitionService.getAwardDetails(
            rootState.userCorpDetail,
            awardId
        );
        return res.data.data;
    },
    async [GET_REWARD_CARDS]({ rootState }, awardId: string) {
        const res = await RecognitionService.getEmployeeDetails(
            rootState.userCorpDetail,
            {
                employeeId: rootState.userCorpDetail.employeeID,
                type: 'BENEFICIARY',
            }
        );
        return res.data.data;
    },
};

export default actions;
