import { AxiosInstance } from 'axios';

import {
    createAxiosInstance,
    ExtendedAxiosRequestConfig,
} from '@zeta/http-client';

class SpotlightcoreService {
    private axios: AxiosInstance;

    constructor(config: ExtendedAxiosRequestConfig) {
        this.axios = createAxiosInstance(config);
    }

    private get servicePath() {
        return `/spotlight-core`;
    }

    public setEmployee(params: any, payload: any) {
        return this.axios.post(
            `${this.servicePath}/tenants/${params.tenantId}/updateEmployeeAttributes`,
            {
                ...payload,
            }
        );
    }
    public fetchTenantInfo(pathParams: any, payload: any): Promise<any> {
        if (pathParams.tenantId) {
            return this.axios.get(
                `${this.servicePath}/tenants/${pathParams.tenantId}/getEmployeeLoginInfo`,
                {
                    params: payload,
                }
            );
        } else {
            return this.axios.get(
                `${__APP__.CORPCORE.BASE_URL}${__APP__.CORPCORE.BASE_PATH}/getEmployeeLoginInfo`,
                {
                    params: payload,
                }
            );
        }
    }
    public resolveEmployee(payload: any = {}): Promise<any> {
        return this.axios.get(`${this.servicePath}/getEmployeeProfile`, {
            params: payload,
        });
    }
    public getCompanyAttributes(pathParams: any) {
        return this.axios.get(
            `${this.servicePath}/tenants/${pathParams.tenantId}/esps/${pathParams.espID}/corporates/${pathParams.corpID}/companies/${pathParams.compID}/theme`,
            {
                params: {
                    // espID: params.espID,
                    // corporateID: params.corpID,
                    // companyID: params.compID
                },
            }
        );
    }
}

const config = {
    baseURL: __APP__.SPOTLIGHT_CORE.BASE_URL,
    resolveAuthToken: () => {
        return localStorage.getItem('@zeta::authToken') || '';
    },
};
export default new SpotlightcoreService(config);
