import { ModuleTree } from 'vuex';
import { RootState } from './types';
import benefitStore, { BENEFITS_STORE } from '../modules/benefits/store/index';
import feedStore, { FEED_STORE } from '../modules/feed/store/index';
import profileStore, { PROFILE_STORE } from './../modules/profile/store/index';
import recognitionStore, {
    RECOGNITION_STORE,
} from '../modules/recognition/store/index';
import discountsStore, {
    DISCOUNTS_STORE,
} from '../modules/discounts/store/index';

//import voucherStore, { VOUCHER_STORE } from "../modules/voucher/store/index";

const modules: ModuleTree<RootState> = {
    [BENEFITS_STORE]: benefitStore,
    [FEED_STORE]: feedStore,
    [DISCOUNTS_STORE]: discountsStore,
    [RECOGNITION_STORE]: recognitionStore,
    [PROFILE_STORE]: profileStore,
};

export default modules;
