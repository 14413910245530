import store from '@/store';
import { Events, EventBus } from '@/commons/eventBus';
import { getDeviceType } from '@/commons/Utils';

const enabledDeviceType = ['WEB', 'MWEB', 'APP'];
const enabledENVs = ['PREPROD'];

function pendoTrack(name: string, data: any) {
    const w: any = window;
    if (w.pendo && w.pendo.isReady && w.pendo.isReady()) {
        return w.pendo.track(name, data);
    } else {
        setTimeout(function() {
            pendoTrack(name, data);
        }, 500);
    }
}

export default function init() {
    let deviceType = getDeviceType();
    const isEnabled = true;

    if (isEnabled) {
        deviceType = deviceType === 'APP' ? 'WEBVIEW' : deviceType;
        const {
            userID,
            companyID,
            corporateID,
            email,
            gender,
            phoneNumber,
            name,
        } = store.state.userDetail;
        const w: any = window;
        const visitorId = `spotlight_${__ENV__.toLowerCase()}_user_id_${userID}`;
        const accountId = `spotlight_${__ENV__.toLowerCase()}_corp_id_${corporateID}_company_id_${companyID}`;
        w.pendo.initialize({
            visitor: {
                id: visitorId,
                email: email,
                ['full_name']: name,

                gender:
                    gender === 'M'
                        ? 'MALE'
                        : gender === 'F'
                        ? 'FEMALE'
                        : gender,
                phoneNumber,
                deviceType,
            },
            account: {
                id: accountId, // Highly recommended
            },
            annotateUrl: () => {
                return {
                    include: { deviceType },
                };
            },
        });
        EventBus.$on(Events.logPendoEvent, (name: string, attr: any = {}) => {
            //  console.log('name log pendo event==> ', name)
            if (name) {
                pendoTrack(
                    name,
                    Object.assign(attr, {
                        deviceType,
                        visitorId,
                        accountId,
                        email,
                    })
                );
            }
        });
        EventBus.$on(Events.showPendoGuideByName, (name: string) => {
            if (name && w.pendo) {
                setTimeout(() => {
                    w.pendo.showGuideByName(name);
                }, 1000);
            }
        });
    }
}
