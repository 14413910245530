
module.exports = {
  "bulmaCssVariablesDefs": {
    "danger": {
      "calls": [
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "lighten",
          "fnArg": "800",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        }
      ]
    },
    "dangeroneten": {
      "calls": []
    },
    "dangeronetwentyfive": {
      "calls": []
    },
    "dangerten": {
      "calls": [
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "lighten",
          "fnArg": "800",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        }
      ]
    },
    "primary": {
      "calls": [
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "lighten",
          "fnArg": "150",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "25",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "50",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "lighten",
          "fnArg": "800",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        }
      ]
    },
    "primaryeighty": {
      "calls": []
    },
    "primaryfive": {
      "calls": [
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "lighten",
          "fnArg": "800",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        }
      ]
    },
    "primaryforty": {
      "calls": []
    },
    "primaryonefifty": {
      "calls": []
    },
    "primaryoneforty": {
      "calls": [
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "lighten",
          "fnArg": "800",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        }
      ]
    },
    "primaryonesixty": {
      "calls": [
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "lighten",
          "fnArg": "800",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        }
      ]
    },
    "primaryoneten": {
      "calls": []
    },
    "primaryonetwenty": {
      "calls": [
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "lighten",
          "fnArg": "800",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        }
      ]
    },
    "primaryonetwentyfive": {
      "calls": []
    },
    "primarysixty": {
      "calls": [
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "lighten",
          "fnArg": "800",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        }
      ]
    },
    "primaryten": {
      "calls": [
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "lighten",
          "fnArg": "800",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        }
      ]
    },
    "primarytwenty": {
      "calls": [
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "lighten",
          "fnArg": "800",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        }
      ]
    },
    "secondary": {
      "calls": []
    },
    "success": {
      "calls": [
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "lighten",
          "fnArg": "800",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        }
      ]
    },
    "successten": {
      "calls": [
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "lighten",
          "fnArg": "800",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        }
      ]
    },
    "warning": {
      "calls": [
        {
          "fn": "darken",
          "fnArg": "1000",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "5",
          "composeArg": null
        },
        {
          "fn": "darken",
          "fnArg": "500",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "80",
          "composeArg": null
        },
        {
          "fn": "lighten",
          "fnArg": "800",
          "composeArg": null
        },
        {
          "fn": "rgba",
          "fnArg": "90",
          "composeArg": null
        }
      ]
    },
    "warningten": {
      "calls": []
    }
  }
}
