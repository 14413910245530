export const SET_RECIEVED_AWARDS_DATA = 'setRecievedAwardsData';
export const SET_NOMINATIONS_APPROVAL_PENDING_LIST =
    'setNominationsApprovalPendingList';
export const UPDATE_PROGRAM_CURRENCY = 'updateProgramCurrency';
export const SET_PROGRAM_TO_BADGE_LIST = 'setProgramToBadgeList';
export const SET_CATEGORY_LIST = 'setCategoryList';
export const SET_ORDER_DETAILS = 'setOrderDetails';

export const CREATE_BASKET = 'createBasket';
export const ADD_BASKET_ITEM = 'addBasketItem';
export const CHANGE_BASKET_ITEM_QUANTITY = 'changeBasketItemQuantity';
export const REMOVE_BASKET_ITEM = 'removeBasketItem';
export const RESET_BASKET = 'resetBasket';
export const INITIALIZE_BASKET_FROM_LOCALSTORAGE =
    'initializeBasketFromLocalstorage';
export const UPDATE_COMPLETE_BASKET = 'updateCompleteBasket';
