import { MutationTree } from 'vuex';
import { ProfileState } from './profile.types';
import { UPDATE_BILLING_ADDRESS } from './profile.mutations.types';

const mutations: MutationTree<ProfileState> = {
    [UPDATE_BILLING_ADDRESS](state, data) {
        state.billingAddresses = data;
    },
};

export default mutations;
