
import { Vue, Component, Prop } from 'vue-property-decorator';
import { State, Getter } from 'vuex-class';
import { CipherSSO, AuthStore } from '@zeta/authentication';
import Avatar from './Avatar.vue';
import { EventBus, Events } from '@/commons/eventBus';
import { GET_COMPANY_ATTRIBUTES } from '@/store/getters.type';

@Component({ components: { Avatar } })
export default class Topbar extends Vue {
    @State('userDetail')
    private userDetail!: any;

    @Getter(GET_COMPANY_ATTRIBUTES) public companyAttributes!: any;

    private openUserProfilePage() {
        this.$router.push('/profile');
    }
    private logout() {
        EventBus.$emit(Events.logout);
        // const sandboxId = AuthStore.getItem('sandbox'),
        //     tenantId = AuthStore.getItem('tenant'),
        //     domainId = AuthStore.getItem('domain');
        // CipherSSO.logout(
        //     `${window.location.origin}/?sandbox=${sandboxId}&tenant=${tenantId}&domain=${domainId}`
        // );
    }
}
