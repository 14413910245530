import dayjs from 'dayjs';

export function convertEpochToDateObj(epochTime: any) {
    return new Date(epochTime * 1000);
}

export function convertDateToEpoch(date: any) {
    return date ? Math.floor(date.getTime() / 1000) : date;
}

export function getFormattedAmount(amount: any, config: any) {
    const value: any = Number(amount / config.denominationFactor)
        .toLocaleString()
        .split('.');

    value[1] = (value[1] || '')
        .padEnd(config.fixedValue, '0')
        .slice(0, config.fixedValue);

    return value.join('.');
}

export function isValidPath(sidebarItems: any[], path: string | undefined) {
    if (path === '/session' || path === '/user-profile') {
        return true;
    }

    if (!path || path === '/') {
        return false;
    }

    return (
        sidebarItems.findIndex((sidebarItem: any) => {
            const itemPath: string = sidebarItem.path;
            return path.indexOf(itemPath) === 0;
        }) !== -1
    );
}
export function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
    return re.test(String(email).toLowerCase());
}

export function debounce(fn: any, delay: any) {
    let timerId: any = null;

    return function(...args: any) {
        clearTimeout(timerId);
        timerId = setTimeout(function() {
            fn(...args);
        }, delay);
    };
}

export function throttle(fn: any, delay: any) {
    let timerId: any = null;

    return function() {
        if (!timerId) {
            timerId = setTimeout(function() {
                fn();
                timerId = null;
            }, delay);
        }
    };
}

export function toCamelCase(str: string) {
    return str
        .split(' ')
        .map(function(word, index) {
            // If it is the first word make sure to lowercase all the chars.
            if (index === 0) {
                return word.toLowerCase();
            }
            // If it is not the first word only upper case the first char and lowercase the rest.
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join('');
}
export function getDeviceType() {
    let deviceType = 'WEB';
    if (window.innerWidth < window.innerHeight) {
        const { zeta, location }: any = window;
        deviceType =
            zeta || (location && location.href.includes('appID='))
                ? 'APP'
                : 'MWEB';
    }
    //console.error('deviceType', deviceType);
    return deviceType;
}

export function getEnv() {
    let env = 'PROD';
    const {
        location: { hostname },
    } = window;
    if (hostname.indexOf('lon') !== -1) {
        env = 'PROD_UK';
    } else if (hostname.indexOf('-pp') !== -1) {
        env = 'PREPROD';
    } else if (hostname.indexOf('-stage') !== -1) {
        env = 'STAGE';
    } else if (hostname.indexOf('localhost') !== -1) {
        env = 'PREPROD';
    }
    return env;
}
export function serializeObject(paramsObj: any) {
    return Object.keys(paramsObj)
        .map(function(key) {
            return key + '=' + encodeURIComponent(paramsObj[key]);
        })
        .join('&');
}

export const firstLetterToUppercase = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const pluralise = (
    word: string,
    val: number,
    plural: string = word + 's'
) => ([1, -1].includes(Number(val)) ? word : plural);

export const timeElapsed = (time: Date | string, shortFormat = false) => {
    if (!time) return '';

    const t = dayjs(time);
    const currentTime = dayjs();

    const minuteDiff = currentTime.diff(t, 'minute');
    const hourDiff = currentTime.diff(t, 'hour');
    const dayDiff = currentTime.diff(t, 'day');
    const monthDiff = currentTime.diff(t, 'month');
    const weekDiff = currentTime.diff(t, 'week');
    const yearDiff = currentTime.diff(t, 'year');

    if (minuteDiff == 0) {
        return 'Just now';
    } else if (minuteDiff < 60) {
        return `${minuteDiff}m${shortFormat ? '' : ' ago'}`;
    } else if (hourDiff < 24) {
        return `${hourDiff}h${shortFormat ? '' : ' ago'}`;
    } else if (dayDiff < 7) {
        return `${dayDiff}d${shortFormat ? '' : ' ago'}`;
    } else if (weekDiff < 4) {
        return `${weekDiff}w${shortFormat ? '' : ' ago'}`;
    } else if (monthDiff < 12) {
        return `${monthDiff}mo${shortFormat ? '' : ' ago'}`;
    } else {
        return `${yearDiff}y${shortFormat ? '' : ' ago'}`;
    }
};

//used in FEEDS-> to get the image url or color from assets array
export const getAssetFromType = (
    imageAssets: any,
    type: string,
    dataType = 'image'
) => {
    if (!imageAssets) {
        return '';
    }
    const image = imageAssets.find((obj: any) => {
        return obj.type === type;
    });
    if (dataType === 'image') {
        return image && image.url ? image.url : '';
    } else if (dataType === 'color') {
        return image?.backgroundColor || '';
    }
    return image?.url || '';
};

function toBase64(blob: any) {
    return new Promise((resolve: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function() {
            resolve(reader.result);
        };
    });
}
export async function downloadAllFiles(files: any[]) {
    if (files.length) {
        const $body = document.getElementsByTagName('body')[0];
        const file: any = files.pop();
        const fileData = await fetch(file.url);
        const blobData = await fileData.blob();
        const base64Data: any = await toBase64(blobData);
        const $el = document.createElement('a');
        $el.download = file.name || 'ticket';
        const data = base64Data.split(',');
        $el.href = `${data[0]},${encodeURIComponent(data[1])}`;
        $body.appendChild($el);
        $el.click();
        $el.remove();
        downloadAllFiles(files);
    }
}
