export const IS_MOBILE_VIEW = 'isMobileView';
export const GET_USER_CORP_DETAILS = 'userCorpDetails';
export const SHOW_AWARD_POPUP = 'showAwardPopup';
export const GET_AWARDS_LIST = 'getAwardsList';
export const GET_AWARD_BY_ID = 'getAwardById';
export const GET_PROGRAM_LIST = 'getProgramList';
export const GET_COMPANY_ATTRIBUTES = 'getCompanyAttributes';
export const GET_MARKETPLACE_PROVIDER = 'getMarketplaceProvider';
export const GET_PROGRAM_DETAILS_BY_ID = 'getProgramDetailsById';
export default null;
