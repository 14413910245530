import { MutationTree } from 'vuex';
import { RootState } from './types';
import {
    UPDATE_DEVICE_TYPE,
    UPDATE_USER_DETAIL,
    UPDATE_USER_CORP_DETAIL,
    UPDATE_AWARDS_LIST,
    SHOW_AWARD_POPUP,
    UPDATE_PROGRAM_LIST,
    UPDATE_COMPANY_ATTRIBUTES,
    UPDATE_USER_ORDER_SUMMARY,
    SET_MARKETPLACE_PROVIDER,
} from './mutations.types';

const mutations: MutationTree<RootState> = {
    [UPDATE_DEVICE_TYPE](state, data: string) {
        state.deviceType = data;
    },
    [UPDATE_USER_DETAIL](state, data: any) {
        state.userDetail = data;
    },
    [UPDATE_USER_CORP_DETAIL](state, data: any) {
        state.userCorpDetail = data;
    },
    [UPDATE_AWARDS_LIST](state, data: any) {
        state.awardsList = data;
    },
    [SHOW_AWARD_POPUP](state, data: any) {
        state.showAwardPopup = data;
    },
    [UPDATE_PROGRAM_LIST](state, data: any) {
        state.programList = data;
    },
    [UPDATE_USER_ORDER_SUMMARY](state, data: any) {
        state.userOrderSummary = data;
    },
    [UPDATE_COMPANY_ATTRIBUTES](state, data: any) {
        state.companyAttributes = data;
    },
    [SET_MARKETPLACE_PROVIDER](state, data: any) {
        state.marketplaceProvider = data;
    },
};

export default mutations;
