
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';

import Layout from '@/components/Layout.vue';
import MobileLayout from '@/components/MobileLayout.vue';

@Component({
    components: {
        Layout,
        MobileLayout,
    },
})
export default class Wrapper extends Vue {}
