export const FETCH_FEED_LIST = 'fetchFeedList';
export const FETCH_BADGE_BY_ID = 'fetchBadgeById';
export const CREATE_AWARD = 'createAward';
export const FETCH_EMPLOYEE_LIST = 'fetchEmployeeList';
export const FETCH_SPECIFIC_FEED_ITEMS = 'fetchSpecificFeedItems';
export const FETCH_REACTIONS = 'fetchReactions';
export const POST_REACTIONS = 'postReactions';
export const UPDATE_REACTION = 'updateReaction';
export const DELETE_REACTION = 'deleteReaction';
export const FETCH_COMMENTS = 'fetchComments';
export const POST_COMMENTS = 'postComments';
export const DELETE_COMMENT = 'deleteComment';
export const GET_AWARD_DETAILS = 'getAwardDetails';
export const GET_POST_COMPONENTS = 'getPostComponents';
export const GET_REWARD_CARDS = 'getRewardCards';
