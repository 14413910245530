
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class Avatar extends Vue {
    @Prop({ default: 'medium' }) private size!: string;
    @Prop({ default: '' }) private imgUrl!: string;
    @Prop({ default: '' }) private text!: string;
    @Prop({ default: '' }) private count!: string;
    @Prop({ default: false }) private uploadable!: boolean;
    @Prop({ default: true }) private hasBorder!: boolean;
    @Prop({ default: false }) private square!: boolean;

    private fileUrl: any = null;

    private onFileChange(file: any) {
        const _URL = window.URL || window.webkitURL;
        this.fileUrl = _URL.createObjectURL(file);

        this.$emit('fileSelected', file, this.fileUrl);
    }
}
