import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { FeedState } from './feed.types';
const getters: GetterTree<FeedState, RootState> = {
    // [GET_PROGRAM_DETAILS_BY_ID](state, id: string) {
    //     return state.programList.find((program: any) => {
    //         return program.id === id;
    //     });
    // },
};

export default getters;
