import { ColorUpdater } from 'bulma-css-vars';
import Color from 'color';
import { bulmaCssVariablesDefs } from '../bulma-generated/bulma-colors';

const colorNames = Object.freeze({
    PRIMARY: 'primary',
    SECONDARY: 'primary',
    BACKGROUND: 'background',
    POSITIVE: 'positive',
    NEGATIVE: 'negative',
    NEUTRAL: 'neutral',
});

function updateColors(colorName: string, value: string, derivedColors?: any) {
    // create color updater
    const colorUpdater = new ColorUpdater(bulmaCssVariablesDefs);

    colorUpdater.updateVarsInDocument(colorName, value);

    for (const color in derivedColors) {
        colorUpdater.updateVarsInDocument(color, derivedColors[color]);
    }
}

export default function updateTheme(themeConfig: any) {
    let derivedColors: any = {};

    Object.keys(themeConfig).forEach((colorName: string) => {
        const colorValue = themeConfig[colorName];
        const c = new Color(colorValue);
        const b = Color('black');
        const w = Color('white');

        if (colorName === colorNames.PRIMARY) {
            derivedColors = {
                primaryeighty: c.mix(w, 0.2).hex(),
                primarysixty: c.mix(w, 0.4).hex(),
                primaryforty: c.mix(w, 0.6).hex(),
                primarytwenty: c.mix(w, 0.8).hex(),
                primaryten: c.mix(w, 0.9).hex(),
                primaryfive: c.mix(w, 0.95).hex(),
                primaryoneten: c.mix(b, 0.1).hex(),
                primaryonetwentyfive: c.mix(b, 0.25).hex(),
                primaryonetwenty: c.mix(b, 0.2).hex(),
                primaryoneforty: c.mix(b, 0.4).hex(),
                primaryonefifty: c.mix(b, 0.5).hex(),
                primaryonesixty: c.mix(b, 0.6).hex(),
            };

            updateColors(colorName, colorValue, derivedColors);
        } else if (colorName === colorNames.SECONDARY) {
            updateColors(colorName, colorValue);
        } else if (colorName === colorNames.BACKGROUND) {
            updateColors(colorName, colorValue);
        } else if (colorName === colorNames.POSITIVE) {
            derivedColors = {
                successten: c.mix(w, 0.9).hex(),
            };

            updateColors('success', colorValue, derivedColors);
        } else if (colorName === colorNames.NEGATIVE) {
            derivedColors = {
                dangerten: c.mix(w, 0.9).hex(),
                dangeroneten: c.mix(b, 0.1).hex(),
                dangeronetwentyfive: c.mix(b, 0.25).hex(),
            };

            updateColors('danger', colorValue, derivedColors);
        } else if (colorName === colorNames.NEUTRAL) {
            derivedColors = {
                warningten: c.mix(w, 0.9).hex(),
            };

            updateColors('warning', colorValue, derivedColors);
        }
    });
}
