import { MutationTree } from 'vuex';
import { BenefitState } from './recognition.types';
import {
    SET_RECIEVED_AWARDS_DATA,
    SET_NOMINATIONS_APPROVAL_PENDING_LIST,
    UPDATE_PROGRAM_CURRENCY,
    SET_PROGRAM_TO_BADGE_LIST,
    SET_CATEGORY_LIST,
    SET_ORDER_DETAILS,
    CREATE_BASKET,
    ADD_BASKET_ITEM,
    CHANGE_BASKET_ITEM_QUANTITY,
    REMOVE_BASKET_ITEM,
    RESET_BASKET,
    INITIALIZE_BASKET_FROM_LOCALSTORAGE,
    UPDATE_COMPLETE_BASKET,
} from './recognition.mutations.types';
import WebStorage from '@/commons/web.localStorage.ts';
const mutations: MutationTree<BenefitState> = {
    [UPDATE_PROGRAM_CURRENCY](state, data) {
        state.currentProgramCurreny = data;
    },
    [SET_PROGRAM_TO_BADGE_LIST](state, data) {
        state.programIdToBadgeList = data;
    },
    [SET_CATEGORY_LIST](state, data: []) {
        state.categoryList = data;
    },
    [SET_ORDER_DETAILS](state, data: {}) {
        state.orderDetails = data;
    },
    [SET_RECIEVED_AWARDS_DATA](state, data) {
        state.recievedAwardsData = data;
    },
    [CREATE_BASKET](state, data: {}) {
        const program: any = WebStorage.getItem('program');
        state.basket = data;
        WebStorage.setItem(
            'rewardsBasket' + program.rewardOfferingId,
            state.basket
        );
    },
    [INITIALIZE_BASKET_FROM_LOCALSTORAGE](state) {
        const program: any = WebStorage.getItem('program');
        if (program) {
            if (
                WebStorage.hasItem('rewardsBasket' + program.rewardOfferingId)
            ) {
                //WebStorage.removeItem('rewardsBasket')
                state.basket = WebStorage.getItem(
                    'rewardsBasket' + program.rewardOfferingId
                );
            } else {
                state.basket = {
                    totalAmount: 0,
                    finalAmount: 0,
                    items: [],
                };
                WebStorage.setItem(
                    'rewardsBasket' + program.rewardOfferingId,
                    state.basket
                );
            }
        }
    },

    [ADD_BASKET_ITEM](state, data: any) {
        const program: any = WebStorage.getItem('program');
        state.basket.items.push(data);
        state.basket.totalAmount += data.totalAmount;
        state.basket.finalAmount += data.finalAmount;
        WebStorage.setItem(
            'rewardsBasket' + program.rewardOfferingId,
            state.basket
        );
    },
    [CHANGE_BASKET_ITEM_QUANTITY](state, data: any) {
        const program: any = WebStorage.getItem('program');
        for (let i = 0; i < state.basket.items.length; i += 1) {
            if (
                state.basket.items[i].id == data.id &&
                state.basket.items[i].faceValue == data.faceValue
            ) {
                state.basket.totalAmount -= state.basket.items[i].totalAmount;
                state.basket.finalAmount -= state.basket.items[i].finalAmount;
                state.basket.totalAmount += data.totalAmount;
                state.basket.finalAmount += data.finalAmount;
                state.basket.items[i].quantity = data.quantity;
                state.basket.items[i].totalAmount = data.totalAmount;
                state.basket.items[i].finalAmount = data.finalAmount;
            }
        }
        WebStorage.setItem(
            'rewardsBasket' + program.rewardOfferingId,
            state.basket
        );
    },
    [REMOVE_BASKET_ITEM](state, data: any) {
        const program: any = WebStorage.getItem('program');
        for (let i = 0; i < state.basket.items.length; i += 1) {
            if (
                state.basket.items[i].id == data.id &&
                state.basket.items[i].faceValue == data.faceValue
            ) {
                state.basket.totalAmount -= state.basket.items[i].totalAmount;
                state.basket.finalAmount -= state.basket.items[i].finalAmount;
            }
        }
        state.basket.items = state.basket.items.filter((el: any) => {
            if (el.id == data.id && el.faceValue == data.faceValue) {
                return false;
            } else {
                return true;
            }
        });
        WebStorage.setItem(
            'rewardsBasket' + program.rewardOfferingId,
            state.basket
        );
    },

    [RESET_BASKET](state, data: {}) {
        const program: any = WebStorage.getItem('program');
        state.basket = {
            items: [],
            totalAmount: 0,
            finalAmount: 0,
            totalDiscount: 0,
        };
        WebStorage.setItem(
            'rewardsBasket' + program.rewardOfferingId,
            state.basket
        );
    },
    [UPDATE_COMPLETE_BASKET](state, data: any) {
        const program: any = WebStorage.getItem('program');
        state.basket = data;
        WebStorage.setItem(
            'rewardsBasket' + program.rewardOfferingId,
            state.basket
        );
    },
    [SET_NOMINATIONS_APPROVAL_PENDING_LIST](state, data) {
        state.nominationsApprovalPendingList = data;
    },
};
export default mutations;
