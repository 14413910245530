
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import { getDeviceType } from '@/commons/Utils';
import { UPDATE_DEVICE_TYPE } from '@/store/mutations.types';
import DefaultLayoutWrapper from './DefaultLayoutWrapper.vue';
import { isLoggedIn } from '@/utils/auth';
import AuthLayoutWrapper from '@/AuthLayoutWrapper.vue';
@Component({ components: { AuthLayoutWrapper, DefaultLayoutWrapper } })
export default class App extends Vue {
    // private sam: string = __ENV__;
    @Mutation(UPDATE_DEVICE_TYPE) private updateDeviceType: any;

    private windowWidth = window.innerWidth;
    private setMode(e: any = null) {
        const deviceType = getDeviceType();
        const tempWindowWidth = window.innerWidth;
        if (tempWindowWidth !== this.windowWidth || !e) {
            this.windowWidth = tempWindowWidth;
            this.updateDeviceType(deviceType);
            const w: any = window;
            if (w.FreshworksWidget) {
                if (deviceType === 'WEB') {
                    if (!this.isUserLoggedIn) {
                        w.FreshworksWidget('hide', 'launcher');
                    } else {
                        w.FreshworksWidget('show', 'launcher');
                    }
                } else {
                    w.FreshworksWidget('hide', 'launcher');
                }
            }
        }
    }
    private get isUserLoggedIn() {
        return isLoggedIn();
    }
    private getRouteType() {
        const currentRoute = window.location.href || '';
        if (currentRoute.indexOf('/auth/') > -1) {
            return 'AUTH';
        }
        //Will add more cases in future here, like 'AUTH','PUBLIC','PRIVATE'
        //Auth routes can not render for loggedin user, Public routes can render for both, Private for loggedin only
        return 'PRIVATE';
    }
    @Watch('$route', { immediate: true, deep: true }) private urlChanged() {
        //WIP Need to investigate whether deep needed or not
        //        const currentRoute = this?.$route?.name || ''; // returning empty on 1st render
        const routeType = this.getRouteType();
        if (routeType === 'PRIVATE' && !this.isUserLoggedIn) {
            this.$router.push(`/auth/login${location.search}`);
            return;
        }
        if (routeType === 'AUTH' && this.isUserLoggedIn) {
            //temporary: needs more cases for about,policy etc pages,
            //For now, it assumes that all public pages are
            this.$router.push('/');
            return;
        }
    }
    private get layout() {
        const currentRoute = this?.$route?.name || ''; //WIP UNKNOWN case
        if (currentRoute.indexOf('AUTH_') > -1) {
            return 'AUTH';
        }
        return 'DEFAULT';
    }

    private created() {
        this.setMode();
        window.addEventListener('resize', (e: any) => {
            this.setMode(e);
        });
        const w: any = window;
        if (w.FreshworksWidget) {
            w.FreshworksWidget('hide', 'ticketForm', [
                'custom_fields.cf_ah_id',
                'custom_fields.cf_unique_id',
                'custom_fields.cf_company_id',
                'custom_fields.cf_company_name',
            ]);
        }
    }
}
