import { AxiosInstance, AxiosPromise } from 'axios';
import Axios from 'axios';
import { getEnv } from '@/commons/Utils';

import {
    createAxiosInstance,
    ExtendedAxiosRequestConfig,
} from '@zeta/http-client';

const baseURL: any = {
    STAGE: 'https://spotlight-recognition-spotlight.mum1-stage.zeta.in',
    PREPROD: 'https://spotlight-recognition-spotlight.mum1-pp.zeta.in',
    PROD: 'https://spotlight-recognition-spotlight.mum1.zeta.in',
    PROD_UK: 'https://sb1-god-spotlight.lon1.zetaapps.in',
};

class FeedService {
    private axios: AxiosInstance;

    constructor(config: ExtendedAxiosRequestConfig) {
        this.axios = createAxiosInstance(config);
    }

    private get servicePath() {
        return `/spotlight-recognition/1.0`;
    }

    public fetchBadgeListByEmployee(pathParams: any, params: any) {
        return this.axios.request({
            method: 'GET',
            url: `${this.servicePath}/tenant/${pathParams.tenantId}/esp/${pathParams.espId}/corporate/${pathParams.corpId}/company/${pathParams.compId}/badge/employee`,
            params,
        });
    }

    public fetchBadgeDetail(params: any, badgeId: any) {
        return this.axios.request({
            method: 'GET',
            url: `${this.servicePath}/tenant/${params.tenantId}/esp/${params.espId}/corporate/${params.corpId}/company/${params.compId}/badge/${badgeId}`,
        });
    }

    public fetchFeeds(params: any, config = {}) {
        return this.axios.request({
            method: 'GET',
            url: `${this.servicePath}/tenant/${params.tenantId}/esp/${params.espId}/corporate/${params.corpId}/company/${params.compId}/badge/feed`,
            ...config,
        });
    }

    public createAward(params: any, payload: any) {
        const { tenantId, espId, corpId, compId } = params;
        return this.axios.post(
            `${this.servicePath}/tenant/${tenantId}/esp/${espId}/corporate/${corpId}/company/${compId}/award`,
            { espId, corpId, compId, ...payload }
        );
    }

    public processNominationAward(
        params: any,
        apiParams: any,
        payload: any,
        config: any = {}
    ) {
        return this.axios.request({
            method: 'POST',
            url: `${this.servicePath}/tenant/${params.tenantId}/esp/${params.espId}/corporate/${params.corpId}/company/${params.compId}/program/${apiParams.programId}/award/${apiParams.awardId}`,
            params: apiParams,
            ...config,
            data: payload,
        });
    }

    public fetchProgramList(params: any, config = {}) {
        return this.axios.request({
            method: 'GET',
            url: `${this.servicePath}/tenant/${params.tenantId}/esp/${params.espId}/corporate/${params.corpId}/company/${params.compId}/program/list`,
            ...config,
        });
    }

    public fetchAwardList(
        params: any,
        apiParams: any,
        programId: string,
        config = {}
    ) {
        return this.axios.request({
            method: 'GET',
            url: `${this.servicePath}/tenant/${params.tenantId}/esp/${params.espId}/corporate/${params.corpId}/company/${params.compId}/program/${programId}/award/list`,
            params: apiParams,
            ...config,
        });
    }

    public fetchAwardDistribution(
        params: any,
        apiParams: any,
        programId: string,
        config = {}
    ) {
        return this.axios.request({
            method: 'GET',
            url: `${this.servicePath}/tenant/${params.tenantId}/esp/${params.espId}/corporate/${params.corpId}/company/${params.compId}/program/${programId}/awardDistribution`,
            params: apiParams,
            ...config,
        });
    }

    public fetchNominationsApprovalPendingList(
        params: any,
        apiParams: any,
        programId: string,
        config = {}
    ) {
        return this.axios.request({
            method: 'GET',
            url: `${this.servicePath}/tenant/${params.tenantId}/esp/${params.espId}/corporate/${params.corpId}/company/${params.compId}/program/${programId}/award/nominationApprovalPending`,
            params: apiParams,
            ...config,
        });
    }

    public fetchPointsHistory(
        params: any,
        apiParams: any,
        programId: string,
        userId: string,
        config = {}
    ) {
        return this.axios.request({
            method: 'GET',
            url: `${this.servicePath}/tenant/${params.tenantId}/esp/${params.espId}/corporates/${params.corpId}/company/${params.compId}/program/${programId}/employee/${userId}/pointsHistory`,
            params: apiParams,
            ...config,
        });
    }

    public fetchSpecificFeedItems(params: any, config: any) {
        return this.axios.request({
            method: 'GET',
            url: `${this.servicePath}/tenant/${params.tenantId}/esp/${params.espId}/corporate/${params.corpId}/company/${params.compId}/awardInRange`,
            params: config,
        });
    }

    public getAwardDetails(params: any, awardId: string) {
        return this.axios.request({
            method: 'GET',
            url: `${this.servicePath}/tenant/${params.tenantId}/esp/${params.espId}/corporate/${params.corpId}/company/${params.compId}/award/${awardId}`,
        });
    }
    public getEmployeeDetails(params: any, config: any) {
        return this.axios.request({
            method: 'GET',
            url: `${this.servicePath}/tenant/${params.tenantId}/esp/${params.espId}/corporate/${params.corpId}/company/${params.compId}/program/employeeDetails`,
            params: config,
        });
    }
}

const config = {
    baseURL: __APP__.RECOGNITION.BASE_URL,
    resolveAuthToken: () => {
        const token: string = localStorage.getItem('@zeta::authToken') || '';
        return token;
    },
};
export default new FeedService(config);
