import { RouteConfig } from 'vue-router';
import { MODULES, pages } from '@/commons/constants';

const Recognition = () =>
    import(
        /* webpackChunkName: "RecognitionPage" */ '@/modules/recognition/Recognition.vue'
    );

const RecognitionLandingView = () =>
    import(
        /* webpackChunkName: "RecognitionLandingPage" */ '@/modules/recognition/pages/RecognitionLandingView.vue'
    );

const AwardRecievedDetailsPage = () =>
    import(
        /* webpackChunkName: "AwardRecievedDetailsPage" */ '@/modules/recognition/pages/AwardRecievedDetails.vue'
    );

const AwardsSentPage = () =>
    import(
        /* webpackChunkName: "AwardsSentPage" */ '@/modules/recognition/pages/AwardsSentList.vue'
    );

const NominationSentDetailsPage = () =>
    import(
        /* webpackChunkName: "NominationSentDetailsPage" */ '@/modules/recognition/pages/NominationSentDetails.vue'
    );

const ApproveOrDeclineNominationPage = () =>
    import(
        /* webpackChunkName: "ApproveOrDeclineNominationPage" */ '@/modules/recognition/pages/ApproveOrDeclineNomination.vue'
    );

const ProgramChooserPage = () =>
    import(
        /* webpackChunkName: "programChooserPage" */ '@/modules/recognition/pages/ProgramChooser.vue'
    );

const RewardsHistoryPage = () =>
    import(
        /* webpackChunkName: "rewardsHistoryPage" */ '@/modules/recognition/pages/RewardsHistory.vue'
    );

const NominationsApprovalListPage = () =>
    import(
        /* webpackChunkName: "nominationsApprovalListPage" */ '@/modules/recognition/pages/NominationsApprovalList.vue'
    );

//REWARDS
const RewardsLandingPage = () =>
    import(
        /* webpackChunkName: "rewardsLandingPage" */ '@/modules/recognition/pages/rewards/RewardsLandingPage.vue'
    );
const RewardsItemPage = () =>
    import(
        /* webpackChunkName: "rewardsItemPage" */ '@/modules/recognition/pages/rewards/RewardsItemPage.vue'
    );
const RewardsCategoryPage = () =>
    import(
        /* webpackChunkName: "rewardsCategoryPage" */ '@/modules/recognition/pages/rewards/RewardsCategoryPage.vue'
    );

const AllCategoriesPage = () =>
    import(
        /* webpackChunkName: "allCategoriesPage" */ '@/modules/recognition/pages/rewards/AllCategories.vue'
    );

const RewardsOrderSummary = () =>
    import(
        /* webpackChunkName: "rewardsOrderSummaryPage" */ '@/modules/recognition/pages/rewards/RewardsOrderSummary.vue'
    );
const RewardsBasketPage = () =>
    import(
        /* webpackChunkName: "rewardsOrderSummaryPage" */ '@/modules/recognition/pages/rewards/RewardsBasketPage.vue'
    );

const AwardDescription = () =>
    import(
        /* webpackChunkName: "AwardDescription" */ '@/modules/recognition/pages/AwardDescription.vue'
    );

const RecievedAwardsPage = () =>
    import(
        /* webpackChunkName: "RecievedAwardsPage" */ '@/modules/recognition/pages/RecievedAwards.vue'
    );
const routes: RouteConfig[] = [
    {
        path: MODULES.RECOGNITION.path,
        component: Recognition,
        children: [
            {
                path: '',
                name: pages.RecognitionLandingPage.name,
                component: RecognitionLandingView,
            },
            {
                path: 'award/:awardId',
                name: pages.AwardDescription.name,
                component: AwardDescription,
            },
            {
                path: 'awards/recieved/:badgeId',
                name: pages.AwardRecievedDetailsPage.name,
                component: AwardRecievedDetailsPage,
            },
            {
                path: 'awards/sent',
                name: pages.AwardsSentListPage.name,
                component: AwardsSentPage,
            },
            {
                path: 'awards/received',
                name: pages.RecievedAwards.name,
                component: RecievedAwardsPage,
            },
            {
                path: '/nominations/:awardId/:viewType',
                name: pages.NominationSentDetailsPage.name,
                component: NominationSentDetailsPage,
            },
            {
                path: '/processNomination/:awardId',
                name: pages.ApproveOrDeclineNominationPage.name,
                component: ApproveOrDeclineNominationPage,
            },
            {
                path: '/choose-program',
                name: pages.ProgramChooserPage.name,
                component: ProgramChooserPage,
            },
            //REWARDS
            {
                path: 'rewards',
                component: Recognition,
                children: [
                    {
                        path: '',
                        name: pages.RewardsLandingPage.name,
                        component: RewardsLandingPage,
                    },
                    {
                        path: 'item/:id',
                        name: pages.RewardsItemPage.name,
                        component: RewardsItemPage,
                    },
                    {
                        path: 'category/:id',
                        name: pages.RewardsCategoryPage.name,
                        component: RewardsCategoryPage,
                    },
                    {
                        path: 'all-categories',
                        name: pages.RewardsAllCategoriesPage.name,
                        component: AllCategoriesPage,
                    },
                    {
                        path: 'order-summary',
                        name: pages.RewardsOrderSummary.name,
                        component: RewardsOrderSummary,
                    },
                    {
                        path: 'basket',
                        name: pages.RewardsBasketPage.name,
                        component: RewardsBasketPage,
                    },
                ],
            },
            {
                path: '/rewards-history',
                name: pages.RewardsHistoryPage.name,
                component: RewardsHistoryPage,
            },
            {
                path: '/nominations-approval-list',
                name: pages.NominationsApprovalListPage.name,
                component: NominationsApprovalListPage,
            },
        ],
    },
];

export default routes;
