import Vue from 'vue';
import Router from 'vue-router';
import { MODULES_ENUM, MODULES } from '@/commons/constants';
import BenefitsRoutes from '../modules/benefits/routes';
import FeedRoutes from '../modules/feed/routes';
import DiscountRoutes from '../modules/discounts/routes';
import RecognitionRoutes from '@/modules/recognition/routes';
import MoreRoutes from '@/modules/more/routes';
import ProfileRoutes from '@/modules/profile/routes';
import AuthRoutes from '@/modules/auth/routes';
//import VoucherRoutes from "../modules/voucher/routes";
import { pages } from '@/commons/constants';
import { isLoggedIn } from '@/utils/auth';
import { getDeviceType } from '@/commons/Utils';
Vue.use(Router);
const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: () => {
                if (isLoggedIn()) {
                    return MODULES[MODULES_ENUM.FEED].path;
                } else {
                    return '/auth/login';
                }
            },
        },
        // {
        //     path: '/more/user-profile',
        //     component: UserProfilePage,
        //     name: pages.UserProfilePage.name,
        // },
        ...BenefitsRoutes,
        ...FeedRoutes,
        ...RecognitionRoutes,
        ...DiscountRoutes,
        ...MoreRoutes,
        ...ProfileRoutes,
        ...AuthRoutes,
    ],
});

// router.beforeResolve((to, from, next) => {
//     const deviceType = getDeviceType();
//     if (deviceType === 'WEB') {
//         const hideHelpPages = ['FeedLandingPage', 'RecognitionLandingPage'];
//         const w: any = window;
//         if (w.FreshworksWidget && to.name) {
//             if (hideHelpPages.includes(to.name)) {
//                 w.FreshworksWidget('hide', 'launcher');
//             } else {
//                 w.FreshworksWidget('show', 'launcher');
//             }
//         }
//     }
//     next();
// });

export default router;
