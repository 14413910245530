import { Reaction } from './types';
export const MODULES_ENUM = Object.freeze({
    BENEFITS: 'BENEFITS',
    FEED: 'FEED',
    RECOGNITION: 'RECOGNITION',
    DISCOUNTS: 'DISCOUNTS',
    PROFILE: 'PROFILE',
    AUTH: 'AUTH',
});

export const MODULES = Object.freeze({
    [MODULES_ENUM.BENEFITS]: {
        path: '/benefits',
        id: MODULES_ENUM.BENEFITS,
        name: 'Benefits',
    },
    [MODULES_ENUM.RECOGNITION]: {
        path: '/recognition',
        id: MODULES_ENUM.RECOGNITION,
        name: 'Recognition',
    },
    [MODULES_ENUM.FEED]: {
        path: '/feed',
        id: MODULES_ENUM.FEED,
        name: 'Feed',
    },
    [MODULES_ENUM.DISCOUNTS]: {
        path: '/discounts',
        id: MODULES_ENUM.DISCOUNTS,
        name: 'discounts',
    },
    [MODULES_ENUM.PROFILE]: {
        path: '/profile',
        id: MODULES_ENUM.PROFILE,
        name: 'profile',
    },
    [MODULES_ENUM.AUTH]: {
        path: '/auth',
        id: MODULES_ENUM.AUTH,
        name: 'Auth',
    },
});

export const BASE_URL = Object.freeze({
    STAGE: 'https://api.expense.mum1-stage.zeta.in',
    PREPROD: 'https://api.expense.mum1-pp.zeta.in',
    PROD: 'https://api.expense.mum1.zeta.in',
    PROD_UK: 'https://api.expense.mum1.zeta.in',
});

export const pages = Object.freeze({
    BenefitsLandingPage: {
        name: 'BenefitsLandingPage',
    },
    FeedLandingPage: {
        name: 'FeedLandingPage',
    },
    AppreciateApplicationPage: {
        name: 'AppreciateApplicationPage',
    },
    RecognitionLandingPage: {
        name: 'RecognitionLandingPage',
    },
    RewardsLandingPage: {
        name: 'RewardsLandingPage',
    },
    RewardsItemPage: {
        name: 'RewardsItemPage',
    },
    RewardsCategoryPage: {
        name: 'RewardsCategoryPage',
    },
    RewardsAllCategoriesPage: {
        name: 'RewardsAllCategoriesPage',
    },
    RewardsOrderSummary: {
        name: 'RewardsOrderSummary',
    },
    RewardsBasketPage: {
        name: 'RewardsBasketPage',
    },
    DiscountsLandingPage: {
        name: 'DiscountsLandingPage',
    },
    DiscountsItemPage: {
        name: 'DiscountsItemPage',
    },
    DiscountsCategoryPage: {
        name: 'DiscountsCategoryPage',
    },
    DiscountsAllCategoriesPage: {
        name: 'DiscountsAllCategoriesPage',
    },
    DiscountsOrderSummary: {
        name: 'DiscountsOrderSummary',
    },
    DiscountsEWalletPage: {
        name: 'DiscountsEWalletPage',
    },
    DiscountsEWalletUsedPage: {
        name: 'DiscountsEWalletUsedPage',
    },
    DiscountsBasketPage: {
        name: 'DiscountsBasketPage',
    },
    VoucherDetailsPage: {
        name: 'VoucherDetailsPage',
    },
    UserProfilePage: {
        name: 'UserProfilePage',
    },
    AwardRecievedDetailsPage: {
        name: 'AwardRecievedDetailsPage',
    },
    AwardsSentListPage: {
        name: 'AwardsSentListPage',
    },
    AppreciationSentDetailsPage: {
        name: 'AppreciationSentDetailsPage',
    },
    NominationSentDetailsPage: {
        name: 'NominationSentDetailsPage',
    },
    ApproveOrDeclineNominationPage: {
        name: 'ApproveOrDeclineNominationPage',
    },
    AwardsSentDetailsPage: {
        name: 'AwardsSentDetailsPage',
    },
    AwardDetailsPage: {
        name: 'AwardDetailsPage',
    },
    IssueAwardDetailsPage: {
        name: 'IssueAwardDetailsPage',
    },
    IssueNominationAwardDetailsPage: {
        name: 'IssueNominationAwardDetailsPage',
    },
    SelectEmployeePage: {
        name: 'SelectEmployeePage',
    },
    IssueAwardPage: {
        name: 'IssueAwardPage',
    },
    NominationQuestionsPage: {
        name: 'NominationQuestionsPage',
    },
    ProgramChooserPage: {
        name: 'ProgramChooserPage',
    },
    RewardsHistoryPage: {
        name: 'RewardsHistoryPage',
    },
    Authentication: {
        name: 'AUTH_Authentication',
    },
    LoginPage: {
        name: 'AUTH_LoginPage',
    },
    ForgotPasswordPage: {
        name: 'AUTH_ForgotPasswordPage',
    },
    LoginPage2: {
        name: 'AUTH_LoginPage2',
    },
    NominationsApprovalListPage: {
        name: 'NominationsApprovalListPage',
    },
    OrderHistory: {
        name: 'OrderHistory',
    },
    OrderHistoryDetails: {
        name: 'OrderHistoryDetails',
    },
    AwardDescription: {
        name: 'AwardDescription',
    },
    RecievedAwards: {
        name: 'RecievedAwards',
    },
    FeedDetails: {
        name: 'FeedDetails',
    },
});

export const pageWithNoMobileHeader = Object.keys(pages)
    .filter((pageItem: string) => {
        const noHeaderPages = [
            'DiscountsItemPage',
            'DiscountsCategoryPage',
            'RewardsCategoryPage',
            'AwardRecievedDetailsPage',
            'AwardsSentDetailsPage',
            'AppreciationSentDetailsPage',
            'AwardDetailsPage',
            'IssueAwardDetailsPage',
            'IssueNominationAwardDetailsPage',
            'SelectEmployeePage',
            'IssueAwardPage',
            'RewardsItemPage',
            'NominationQuestionsPage',
        ];
        return noHeaderPages.includes(pageItem);
    })
    .map((pageItem: string) => {
        const tempPages: any = pages;
        return tempPages[pageItem].name;
    });

export const PROFILE_ITEMS = Object.freeze({
    VIEW_PROFILE: {
        name: 'View Profile',
        path: '/user-profile',
        //  image: require('@/assets/profile.svg'),
    },
    HELP_AND_SUPPORT: {
        name: 'Help and Support',
        path: '/user',
        //   image: require('@/assets/help.svg'),
    },
    LOGOUT: {
        name: 'Logout',
        path: '/user',
        //    image: require('@/assets/logout.svg'),
    },
});

export const dummyUsers = [
    {
        employeeID: 1548378,
        name: 'Aleshia Tomkiewicz',
        email: 'spt_testemp3@getnada.com',
        phoneNumber: '-1',
        companyID: 19555,
        companyName: 'SL Company One',
        corporateID: 18508,
        attributes: {
            dob: '1990-08-01',
        },
        createdAt: 1603363349718,
        updatedAt: 1603449583201,
        externalEmpCode: 'N/A',
        externalPaygroupCode: null,
        externalCompanyCode: null,
        startDate: null,
        employeeStartDate: null,
        pan: null,
        crn: 'N/A',
        userID: 1122303,
        identifierList: ['EMAIL'],
        state: 'ACTIVE',
        gender: 'MALE',
        homeAddress: null,
        officeAddress: null,
        groupID: null,
        groupName: null,
        beneficiaryEnrolmentDetails: null,
    },
    {
        employeeID: 1548382,
        name: 'Eric Rampy',
        email: 'spt_testemp7@getnada.com',
        phoneNumber: '-1',
        companyID: 19555,
        companyName: 'SL Company One',
        corporateID: 18508,
        attributes: {
            dob: '1990-08-01',
        },
        createdAt: 1603363434630,
        updatedAt: 1603449550215,
        externalEmpCode: 'N/A',
        externalPaygroupCode: null,
        externalCompanyCode: null,
        startDate: null,
        employeeStartDate: null,
        pan: null,
        crn: 'N/A',
        userID: 1122307,
        identifierList: ['EMAIL'],
        state: 'ACTIVE',
        gender: 'MALE',
        homeAddress: null,
        officeAddress: null,
        groupID: null,
        groupName: null,
        beneficiaryEnrolmentDetails: null,
    },
    {
        employeeID: 1548377,
        name: 'Evan Zigomalas',
        email: 'spt_testemp2@getnada.com',
        phoneNumber: '-1',
        companyID: 19555,
        companyName: 'SL Company One',
        corporateID: 18508,
        attributes: {
            dob: '1990-08-01',
        },
        createdAt: 1603363330565,
        updatedAt: 1603449712457,
        externalEmpCode: 'N/A',
        externalPaygroupCode: null,
        externalCompanyCode: null,
        startDate: null,
        employeeStartDate: null,
        pan: null,
        crn: 'N/A',
        userID: 1122302,
        identifierList: ['EMAIL'],
        state: 'ACTIVE',
        gender: 'FEMALE',
        homeAddress: null,
        officeAddress: null,
        groupID: null,
        groupName: null,
        beneficiaryEnrolmentDetails: null,
    },
    {
        employeeID: 1548328,
        name: 'France Andrade',
        email: 'spotlightppuser1@mailinator.com',
        phoneNumber: '-1',
        companyID: 19555,
        companyName: 'SL Company One',
        corporateID: 18508,
        attributes: {
            dob: '1993-10-12',
        },
        createdAt: 1602849171863,
        updatedAt: 1603449701272,
        externalEmpCode: 'N/A',
        externalPaygroupCode: null,
        externalCompanyCode: null,
        startDate: null,
        employeeStartDate: null,
        pan: null,
        crn: 'N/A',
        userID: 1121851,
        identifierList: ['EMAIL'],
        state: 'ACTIVE',
        gender: 'FEMALE',
        homeAddress: null,
        officeAddress: null,
        groupID: null,
        groupName: null,
        beneficiaryEnrolmentDetails: null,
    },
    {
        employeeID: 1548381,
        name: 'Laquita Hisaw',
        email: 'spt_testemp6@getnada.com',
        phoneNumber: '-1',
        companyID: 19555,
        companyName: 'SL Company One',
        corporateID: 18508,
        attributes: {
            dob: '1990-08-01',
        },
        createdAt: 1603363412409,
        updatedAt: 1603449618623,
        externalEmpCode: 'N/A',
        externalPaygroupCode: null,
        externalCompanyCode: null,
        startDate: null,
        employeeStartDate: null,
        pan: null,
        crn: 'N/A',
        userID: 1122306,
        identifierList: ['EMAIL'],
        state: 'ACTIVE',
        gender: 'FEMALE',
        homeAddress: null,
        officeAddress: null,
        groupID: null,
        groupName: null,
        beneficiaryEnrolmentDetails: null,
    },
    {
        employeeID: 1548379,
        name: 'Marg Grasmick',
        email: 'spt_testemp4@getnada.com',
        phoneNumber: '-1',
        companyID: 19555,
        companyName: 'SL Company One',
        corporateID: 18508,
        attributes: {
            dob: '1990-08-01',
        },
        createdAt: 1603363366719,
        updatedAt: 1603449654087,
        externalEmpCode: 'N/A',
        externalPaygroupCode: null,
        externalCompanyCode: null,
        startDate: null,
        employeeStartDate: null,
        pan: null,
        crn: 'N/A',
        userID: 1122304,
        identifierList: ['EMAIL'],
        state: 'ACTIVE',
        gender: 'FEMALE',
        homeAddress: null,
        officeAddress: null,
        groupID: null,
        groupName: null,
        beneficiaryEnrolmentDetails: null,
    },
    {
        employeeID: 1548376,
        name: 'Tyisha Veness',
        email: 'spt_testemp1@getnada.com',
        phoneNumber: '-1',
        companyID: 19555,
        companyName: 'SL Company One',
        corporateID: 18508,
        attributes: {
            dob: '1990-08-01',
        },
        createdAt: 1603363275821,
        updatedAt: 1603449532891,
        externalEmpCode: 'N/A',
        externalPaygroupCode: null,
        externalCompanyCode: null,
        startDate: null,
        employeeStartDate: null,
        pan: null,
        crn: 'N/A',
        userID: 1122301,
        identifierList: ['EMAIL'],
        state: 'ACTIVE',
        gender: 'MALE',
        homeAddress: null,
        officeAddress: null,
        groupID: null,
        groupName: null,
        beneficiaryEnrolmentDetails: null,
    },
    {
        employeeID: 1548380,
        name: 'Ulysses Mcwalters',
        email: 'spt_testemp5@getnada.com',
        phoneNumber: '-1',
        companyID: 19555,
        companyName: 'SL Company One',
        corporateID: 18508,
        attributes: {
            dob: '1990-08-01',
        },
        createdAt: 1603363387456,
        updatedAt: 1603449686739,
        externalEmpCode: 'N/A',
        externalPaygroupCode: null,
        externalCompanyCode: null,
        startDate: null,
        employeeStartDate: null,
        pan: null,
        crn: 'N/A',
        userID: 1122305,
        identifierList: ['EMAIL'],
        state: 'ACTIVE',
        gender: 'FEMALE',
        homeAddress: null,
        officeAddress: null,
        groupID: null,
        groupName: null,
        beneficiaryEnrolmentDetails: null,
    },
];

export const config: any = {
    STAGE: {
        espId: 122,
        corpId: 18508,
        companyId: 19555,
    },
    PREPROD: {
        espId: 122,
        corpId: 18508,
        companyId: 19555,
    },
    PROD: {
        espId: 27,
        corpId: 66399,
        companyId: 66087,
        businessId: 334654,
    },
    PROD_UK: {
        espId: 27,
        corpId: 66399,
        companyId: 66087,
        businessId: 334654,
    },
};

export const REACTIONS = Object.freeze({
    APPLAUD: 'APPLAUD',
    LIKE: 'LIKE',
    INTEREST: 'INTEREST',
    CELEBRATE: 'CELEBRATE',
    HEART: 'HEART',
});

export const REACTION_TYPE_TO_SYMBOL = Object.freeze({
    [REACTIONS.APPLAUD]: 'Applause',
    [REACTIONS.LIKE]: 'Like',
    [REACTIONS.INTEREST]: 'Interest',
    [REACTIONS.CELEBRATE]: 'Celebrate',
    [REACTIONS.HEART]: 'HeartTest',
});
