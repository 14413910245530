import Vue, { PluginFunction, PluginObject } from 'vue';
import Buefy from 'buefy';
import dayjs from 'dayjs';

import './commons/class-component-hooks';
import CurrencyConfig from '@/commons/currencyConfig';
import {
    getFormattedAmount,
    convertEpochToDateObj,
    pluralise,
} from '@/commons/Utils';

import './scss/main.scss';

type PluginList = [PluginObject<any> | PluginFunction<any>, any];

const plugins: PluginList[] = [[Buefy, null]];

const installGlobalPlugins = () => {
    plugins.forEach(item => {
        const [plugin, options] = item;
        if (options) {
            Vue.use(plugin, options);
        } else {
            Vue.use(plugin);
        }
    });
};

const installGlobalFilters = () => {
    Vue.filter('dateFormatter', (date: any) =>
        dayjs(
            typeof date == 'number' ? convertEpochToDateObj(date) : date
        ).format('DD MMM YYYY')
    );
    Vue.filter('dateTimeFormatter', (date: any) =>
        dayjs(
            typeof date == 'number' ? convertEpochToDateObj(date) : date
        ).format('hh:mmA, DD MMM YYYY')
    );

    Vue.filter('amountFormatter', (money: any) => {
        if (!money || typeof money !== 'object') return '';
        const config: any = CurrencyConfig[money.currency || 'Pound'];
        return `${config.symbol}${getFormattedAmount(money.amount, config)}`;
    });
    Vue.filter('vcFormatter', (amount: any) => {
        const value: any = Number(amount / 1).toLocaleString();
        return value;
    });

    Vue.filter('accountFormatter', (accountNumber: any) => {
        return accountNumber.slice(-4).padStart(6, 'x');
    });

    Vue.filter('dateDiff', (date: any) => {
        const now = dayjs(new Date());
        const fromDate = dayjs(new Date(date));
        const y = now.diff(fromDate, 'y');
        const m = now.diff(fromDate, 'M');
        const w = now.diff(fromDate, 'w');
        const d = now.diff(fromDate, 'd');
        const h = now.diff(fromDate, 'h');
        const min = now.diff(fromDate, 'm');
        const s = now.diff(fromDate, 's');
        let text = '';
        if (y) {
            text = `${y}year`;
        } else if (m) {
            text = `${m}month`;
        } else if (w) {
            text = `${w}w`;
        } else if (d) {
            text = `${d}d`;
        } else if (h) {
            text = `${h}h`;
        } else if (min) {
            text = `${min}m`;
        } else {
            text = 'a moment';
        }
        return `${text} ago`;
    });

    Vue.filter('genderFormatter', (genderCode: any) => {
        if (genderCode) {
            if (genderCode === 'M') {
                return 'Male';
            } else if (genderCode === 'F') {
                return 'Female';
            } else {
                return genderCode;
            }
        } else {
            return genderCode;
        }
    });

    Vue.filter('pluralise', pluralise);
};

export default function init() {
    installGlobalPlugins();
    installGlobalFilters();
}
