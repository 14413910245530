// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import store from '@/store';
import { Events, EventBus } from '@/commons/eventBus';
import zetaBridge from '@/services/zetaBridge';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyBOlhC8stEgLmQJNgaiAadUr_iZzE2NmuE',
    authDomain: 'sodexo-uk.firebaseapp.com',
    projectId: 'sodexo-uk',
    storageBucket: 'sodexo-uk.appspot.com',
    messagingSenderId: '134691597148',
    appId: '1:134691597148:web:329cd7a20a73908e186f99',
    measurementId: 'G-SP2HHKCEDT',
};

export default function init(params: any) {
    // Initialize Firebase
    //  if (!params.isApp) {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    EventBus.$on(Events.logFirebaseEvent, (name: string, attr: any = {}) => {
        console.log('firebase event', name, attr);
        const {
            ssoIdentity,
            companyID,
            corporateID,
            email,
            gender,
            phoneNumber,
            employeeID,
        } = store.state.userDetail;
        //  console.log('name log pendo event==> ', name)
        if (name) {
            logEvent(
                analytics,
                name,
                Object.assign(attr, {
                    email,
                    esp_id: store.state.userCorpDetail.espId,
                    corporate_id: corporateID,
                    company_id: companyID,
                    employee_id: employeeID,
                })
            );
        }
    });
    // } else {
    //     EventBus.$on(
    //         Events.logFirebaseEvent,
    //         (name: string, attr: any = {}) => {
    //             const {
    //                 ssoIdentity,
    //                 companyID,
    //                 corporateID,
    //                 email,
    //                 gender,
    //                 phoneNumber,
    //                 employeeID,
    //             } = store.state.userDetail;
    //             //  console.log('name log pendo event==> ', name)
    //             const payload = Object.assign(attr, {
    //                 email,
    //                 esp_id: store.state.userCorpDetail.espId,
    //                 corporate_id: corporateID,
    //                 company_id: companyID,
    //                 employee_id: employeeID,
    //             });
    //             console.log(
    //                 'firebase event - reportEventPlugin',
    //                 name,
    //                 payload
    //             );
    //             if (name) {
    //                 zetaBridge.reportEvent({
    //                     eventName: name,
    //                     eventAttributes: payload,
    //                 });
    //             }
    //         }
    //     );
    // }
}
