import { AxiosInstance, AxiosPromise } from 'axios';

import {
    createAxiosInstance,
    ExtendedAxiosRequestConfig,
} from '@zeta/http-client';

class FeedService {
    private axios: AxiosInstance;

    constructor(config: ExtendedAxiosRequestConfig) {
        this.axios = createAxiosInstance(config);
    }

    private get servicePath() {
        return `/luminos-feed/1.0`;
    }

    public fetchPosts(params: any, config: any = {}) {
        return this.axios.request({
            method: 'GET',
            url: `${this.servicePath}/tenants/${params.tenantId}/subtenants/${params.subTenantId}/posts?pageIndex=${config.pageIndex}&pageSize=${config.pageSize}&sortBy=&sortOrder&requestId=`,
        });
    }

    public fetchSinglePost(params: any) {
        return this.axios.request({
            method: 'GET',
            url: `${this.servicePath}/tenants/${params.tenantId}/subtenants/${params.subTenantId}/posts/${params.postId}`,
        });
    }

    public getReactions(params: any, config = {}) {
        return this.axios.request({
            method: 'GET',
            url: `${this.servicePath}/tenants/${params.tenantId}/subtenants/${params.subTenantId}/posts/${params.postId}/reactions`,
            ...config,
        });
    }

    public postReaction(params: any, payload: any) {
        const { reactingAccountId, reactingAccountName, reaction } = payload;
        return this.axios.post(
            `${this.servicePath}/tenants/${params.tenantId}/subtenants/${params.subTenantId}/posts/${params.postId}/reactions`,
            { reactingAccountId, reactingAccountName, reaction }
        );
    }

    public updateReaction(params: any, payload: any) {
        const { reactingAccountId, reactingAccountName, reaction } = payload;
        return this.axios.put(
            `${this.servicePath}/tenants/${params.tenantId}/subtenants/${params.subTenantId}/posts/${params.postId}/reactions/${params.reactionId}`,
            { reactingAccountId, reactingAccountName, reaction }
        );
    }
    public deleteReaction(params: any, payload: any) {
        return this.axios.delete(
            `${this.servicePath}/tenants/${params.tenantId}/subtenants/${params.subTenantId}/posts/${params.postId}/reactions/${params.reactionId}`
        );
    }

    public getComments(params: any, config: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenants/${params.tenantId}/subtenants/${params.subTenantId}/posts/${params.postId}/comments?pageIndex=${config.pageIndex}&pageSize=${config.pageSize}&sortBy=CREATED_AT&sortOrder`
        );
    }

    public postComment(params: any, payload: any) {
        return this.axios.post(
            `${this.servicePath}/tenants/${params.tenantId}/subtenants/${params.subTenantId}/posts/${params.postId}/comments`,
            { ...payload }
        );
    }

    public deleteComment(params: any) {
        return this.axios.request({
            method: 'DELETE',
            url: `${this.servicePath}/tenants/${params.tenantId}/subtenants/${params.subTenantId}/posts/${params.postId}/comments/${params.commentId}`,
        });
    }

    public getPostComponents(params: any, config: any = {}) {
        return this.axios.get(
            `${this.servicePath}/tenants/${params.tenantId}/subtenants/${params.subTenantId}/posts/${params.postId}/components?pageIndex=${config.pageIndex}&pageSize=${config.pageSize}&sortBy=CREATED_AT&sortOrder`
        );
    }
}

const config = {
    baseURL: __APP__.LUMINOS.BASE_URL,
    resolveAuthToken: () => {
        const token: string = localStorage.getItem('@zeta::authToken') || '';
        return token;
    },
};
export default new FeedService(config);
