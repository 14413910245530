import { RouteConfig } from 'vue-router';
import { MODULES, pages } from '@/commons/constants';

const Discounts = () =>
    import(
        /* webpackChunkName: "DiscountsPage" */ '@/modules/discounts/Discounts.vue'
    );
const DiscountsLandingPage = () =>
    import(
        /* webpackChunkName: "DiscountsLandingPage" */ '@/modules/discounts/pages/DiscountsLandingPage.vue'
    );
const DiscountsItemPage = () =>
    import(
        /* webpackChunkName: "DiscountsItemPage" */ '@/modules/discounts/pages/DiscountsItemPage.vue'
    );
const DiscountsCategoryPage = () =>
    import(
        /* webpackChunkName: "DiscountsCategoryPage" */ '@/modules/discounts/pages/DiscountsCategoryPage.vue'
    );
const AllCategoriesPage = () =>
    import(
        /* webpackChunkName: "AllCategoriesPage" */ '@/modules/discounts/pages/AllCategories.vue'
    );
const DiscountsOrderSummary = () =>
    import(
        /* webpackChunkName: "DiscountsOrderSummaryPage" */ '@/modules/discounts/pages/DiscountsOrderSummary.vue'
    );
const DiscountsBasketPage = () =>
    import(
        /* webpackChunkName: "DiscountsBasketPage" */ '@/modules/discounts/pages/DiscountsBasketPage.vue'
    );
const DiscountsEWalletPage = () =>
    import(
        /* webpackChunkName: "DiscountsEWalletPage" */ '@/modules/discounts/pages/DiscountsEWalletPage.vue'
    );
const DiscountsEWalletUsedPage = () =>
    import(
        /* webpackChunkName: "DiscountsEWalletUsedPage" */ '@/modules/discounts/pages/DiscountsEWalletUsedPage.vue'
    );
const VoucherDetailsPage = () =>
    import(
        /* webpackChunkName: "VoucherDetailsPage" */ '@/modules/discounts/pages/VoucherDetailsPage.vue'
    );

const OrderHistoryPage = () =>
    import(
        /* webpackChunkName: "OrderHistoryPage" */ '@/modules/discounts/pages/order-history/OrderHistory.vue'
    );
const OrderHistoryDetailsPage = () =>
    import(
        /* webpackChunkName: "OrderHistoryDetailsPage" */ '@/modules/discounts/pages/order-history/OrderHistoryDetails.vue'
    );
const routes: RouteConfig[] = [
    //UNCOMMENT all routes to make discount work
    // {
    //     path: MODULES.DISCOUNTS.path,
    //     component: Discounts,
    //     children: [
    //         {
    //             path: '',
    //             name: pages.DiscountsLandingPage.name,
    //             component: DiscountsLandingPage,
    //         },
    //         {
    //             path: 'item/:id',
    //             name: pages.DiscountsItemPage.name,
    //             component: DiscountsItemPage,
    //         },
    //         {
    //             path: 'category/:id',
    //             name: pages.DiscountsCategoryPage.name,
    //             component: DiscountsCategoryPage,
    //         },
    //         {
    //             path: 'all-categories',
    //             name: pages.DiscountsAllCategoriesPage.name,
    //             component: AllCategoriesPage,
    //         },
    //         {
    //             path: 'order-summary',
    //             name: pages.DiscountsOrderSummary.name,
    //             component: DiscountsOrderSummary,
    //         },
    //         {
    //             path: 'basket',
    //             name: pages.DiscountsBasketPage.name,
    //             component: DiscountsBasketPage,
    //         },
    //     ],
    // },
    {
        path: '/more/e-wallet',
        name: pages.DiscountsEWalletPage.name,
        component: DiscountsEWalletPage,
    },
    {
        path: '/more/e-wallet/used',
        name: pages.DiscountsEWalletUsedPage.name,
        component: DiscountsEWalletUsedPage,
    },
    {
        path: '/more/e-wallet/:orderResourceId',
        name: pages.VoucherDetailsPage.name,
        component: VoucherDetailsPage,
    },

    {
        path: '/more/order-history',
        name: pages.OrderHistory.name,
        component: OrderHistoryPage,
    },
    {
        path: '/more/order-history/:orderId',
        name: pages.OrderHistoryDetails.name,
        component: OrderHistoryDetailsPage,
    },
];

export default routes;
