import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { BenefitState } from './recognition.types';
import {
    FETCH_USER_REWARD_BALANCE,
    FETCH_AWARD_LIST,
    FETCH_CATEGORY_LIST,
    FETCH_SALEITEMS_LIST,
    FETCH_SALEITEM_BY_ID,
    FETCH_SUBCATEGORY_LIST,
    FETCH_SALEITEMS_BY_CATEGORY_ID,
    FETCH_ORDER_RESOURCE_FOR_USER,
    FETCH_RESOURCE_DETAILS_BY_ID,
    FETCH_BADGE_BY_ID,
    ORDER_INITIATE,
    ORDER_INITIATE_PAYMENT,
    ORDER_CONFIRM,
    ORDER_PLACE,
    EVALUATE_BASKET,
    IS_BASKET_UPDATE_POSSIBLE,
    FETCH_POINTS_HISTORY,
    FETCH_NOMINATIONS_APPROVAL_PENDING_LIST,
    PROCESS_NOMINATION_AWARD,
    FETCH_SALEITEMS_FILTERS,
    GET_AWARD_DETAILS,
} from './recognition.actions.types';
import RewardService from '@/services/rewards';
import DiscountsService from '@/services/discounts';

import RecognitionService from '@/services/recognition';
import WebStorage from '@/commons/web.localStorage.ts';
import dayjs from 'dayjs';
import {
    SET_NOMINATIONS_APPROVAL_PENDING_LIST,
    SET_RECIEVED_AWARDS_DATA,
} from './recognition.mutations.types';
import PaymentsService from '@/services/payments.ts';
import { v4 as uuidv4 } from 'uuid';

const APPROVED_STATUS = ['NOMINATION_APPROVED', 'AWARD_APPROVED'];
const PENDING_STATUS = ['NOMINATION_APPROVAL_PENDING'];
const DECLINED_STATUS = ['NOMINATION_REJECTED', 'AWARD_REJECTED'];

const actions: ActionTree<BenefitState, RootState> = {
    async [FETCH_USER_REWARD_BALANCE]({ rootState }, params) {
        return new Promise(resolve => {
            let rewardOfferingId: any =
                params.rewardOfferingId || WebStorage.getItem('program') || 0;
            if (rewardOfferingId != 0 && typeof rewardOfferingId != 'string') {
                rewardOfferingId = rewardOfferingId.rewardOfferingId;
            }
            RewardService.fetchUserRewardBalance(
                {
                    ...params,
                    entityId: rootState.userCorpDetail.employeeID,
                    tenantId: rootState.userCorpDetail.tenantId,
                },
                rewardOfferingId,
                rootState.userCorpDetail
            ).then(
                (resp: any) => {
                    resolve(resp.data);
                },
                (err: any) => {
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_POINTS_HISTORY]({ rootState }, params) {
        return new Promise(resolve => {
            let programId: any = WebStorage.getItem('program') || 0;
            if (programId != 0) {
                programId = programId.id;
            }
            const userId: any = rootState.userCorpDetail.employeeID;
            RecognitionService.fetchPointsHistory(
                rootState.userCorpDetail,
                params,
                programId,
                userId
            ).then(
                (resp: any) => {
                    const tempData = resp.data.data;
                    const transactions = tempData.pointsHistory.map(
                        (trx: any) => {
                            trx['timeString'] = dayjs(
                                trx.transactionTime
                            ).format('hh:mmA, D MMM YYYY');
                            return trx;
                        }
                    );
                    resolve({ ...tempData, pointsHistory: transactions });
                },
                (err: any) => {
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },

    async [FETCH_NOMINATIONS_APPROVAL_PENDING_LIST](
        { rootState, commit },
        params
    ) {
        return new Promise(resolve => {
            let programId: any = WebStorage.getItem('program') || 0;
            if (programId != 0) {
                programId = programId.id;
            }

            params = {
                ...params,
                participantId: rootState.userCorpDetail.employeeID,
            };
            RecognitionService.fetchNominationsApprovalPendingList(
                rootState.userCorpDetail,
                params,
                programId
            ).then(
                (resp: any) => {
                    const tempData = resp.data.data;

                    const awards = tempData.awards.map((award: any) => {
                        award['timeString'] = dayjs(
                            award.currentState.updatedAt
                        ).format('D MMM YYYY');
                        award['tags'] = award.criteria.map((tag: string) => {
                            return tag;
                        });

                        if (award.status == 'NOMINATION_APPROVAL_PENDING') {
                            award['status'] = 'PENDING';
                        } else if (award.status == 'NOMINATION_APPROVED') {
                            award['status'] = 'APPROVED';
                        } else if (award.status == 'NOMINATION_REJECTED') {
                            award['status'] = 'DECLINED';
                        }

                        return award;
                    });

                    if (params.audienceType === 'RECEIVER') {
                        commit(SET_RECIEVED_AWARDS_DATA, awards);
                    }

                    commit(SET_NOMINATIONS_APPROVAL_PENDING_LIST, awards);
                    resolve(awards);
                },
                (err: any) => {
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },

    async [FETCH_AWARD_LIST]({ rootState }, params) {
        return new Promise(resolve => {
            let programId: any = WebStorage.getItem('program') || 0;
            if (programId != 0) {
                programId = programId.id;
            }

            params = {
                ...params,
                participantIds: rootState.userCorpDetail.employeeID,
            };
            RecognitionService.fetchAwardList(
                rootState.userCorpDetail,
                params,
                programId
            ).then(
                (resp: any) => {
                    const tempData = resp.data.data;

                    const awards = tempData.entities.map((award: any) => {
                        award['timeString'] = dayjs(
                            award.currentState.updatedAt
                        ).format('D MMM YYYY');
                        award['tags'] = award.criteria.map((tag: string) => {
                            return tag;
                        });

                        if (APPROVED_STATUS.includes(award.status)) {
                            award['status'] = 'APPROVED';
                        } else if (PENDING_STATUS.includes(award.status)) {
                            award['status'] = 'PENDING';
                        } else if (DECLINED_STATUS.includes(award.status)) {
                            award['status'] = 'DECLINED';
                        }

                        return award;
                    });

                    resolve({ awards: awards, ...tempData });
                },
                (err: any) => {
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [PROCESS_NOMINATION_AWARD]({ rootState }, params) {
        return new Promise(resolve => {
            const payload: any = {
                comments: params.comments,
                participantId: params.participantId,
            };

            RecognitionService.processNominationAward(
                rootState.userCorpDetail,
                params,
                payload
            ).then(
                (resp: any) => {
                    resolve(resp.data);
                },
                (err: any) => {
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },
    async [FETCH_BADGE_BY_ID]({ rootState }, badgeId: any) {
        return new Promise(resolve => {
            RecognitionService.fetchBadgeDetail(
                rootState.userCorpDetail,
                badgeId
            ).then(
                (resp: any) => {
                    resolve(resp.data.data);
                },
                (err: any) => {
                    resolve({
                        isError: true,
                        ...err.response,
                    });
                }
            );
        });
    },

    async [FETCH_SALEITEMS_FILTERS](
        { rootState },
        { params, queryString } = {}
    ) {
        const program: any = WebStorage.getItem('program');
        const currencyDetails: any = WebStorage.getItem('currencyDetails');
        const res = await DiscountsService.fetchSaleItemFilters({
            params: {
                ...params,
                catalogueTypes: 'REWARDS',
                externalCurrencyId: currencyDetails.id,
                currencyCode: currencyDetails.auraCode,
                source: 'TACHYON_REWARDS',
                segmentType: 'REWARDS_PRODUCT_OFFERING',
                segmentId: program.rewardOfferingId,
            },
            queryString,
        });

        return res.data.data;
    },

    async [FETCH_CATEGORY_LIST]({ rootState }, params) {
        const program: any = WebStorage.getItem('program');
        const currencyDetails: any = WebStorage.getItem('currencyDetails');
        const res = await DiscountsService.fetchCategories({
            params: {
                catalogueTypes: 'REWARDS',
                externalCurrencyId: currencyDetails.id,
                currencyCode: currencyDetails.auraCode,
                source: 'TACHYON_REWARDS',
                segmentType: 'REWARDS_PRODUCT_OFFERING',
                segmentId: program.rewardOfferingId,
            },
        });
        return res.data.data;
    },
    async [FETCH_SALEITEMS_LIST]({ rootState }, { payload, params }) {
        const program: any = WebStorage.getItem('program');
        const currencyDetails: any = WebStorage.getItem('currencyDetails');
        const res = await DiscountsService.fetchSaleItems(payload.queryString, {
            params: {
                ...params,
                catalogueTypes: 'REWARDS',
                externalCurrencyId: currencyDetails.id,
                currencyCode: currencyDetails.auraCode,
                source: 'TACHYON_REWARDS',
                segmentType: 'REWARDS_PRODUCT_OFFERING',
                segmentId: program.rewardOfferingId,
            },
        });
        return res.data.data;
    },
    async [FETCH_SALEITEM_BY_ID]({ rootState }, payload: any) {
        const res = await DiscountsService.fetchSaleItemById(payload.id, {
            params: { currencyId: payload.currencyId },
        });
        return res.data.data;
    },
    async [FETCH_SUBCATEGORY_LIST]({ rootState }, id: number) {
        const program: any = WebStorage.getItem('program');
        const currencyDetails: any = WebStorage.getItem('currencyDetails');
        const res = await DiscountsService.fetchSubCategoriesById(id, {
            params: {
                catalogueTypes: 'REWARDS',
                externalCurrencyId: currencyDetails.id,
                currencyCode: currencyDetails.auraCode,
                source: 'TACHYON_REWARDS',
                segmentType: 'REWARDS_PRODUCT_OFFERING',
                segmentId: program.rewardOfferingId,
            },
        });
        return res.data.data;
    },
    async [FETCH_SALEITEMS_BY_CATEGORY_ID]({ rootState }, id: number) {
        const program: any = WebStorage.getItem('program');
        const currencyDetails: any = WebStorage.getItem('currencyDetails');
        const res = await DiscountsService.fetchSaleItemsByCategoryId(id, {
            params: {
                catalogueTypes: 'REWARDS',
                externalCurrencyId: currencyDetails.id,
                currencyCode: currencyDetails.auraCode,
                source: 'TACHYON_REWARDS',
                segmentType: 'REWARDS_PRODUCT_OFFERING',
                segmentId: program.rewardOfferingId,
            },
        });
        return res.data.data;
    },
    async [FETCH_ORDER_RESOURCE_FOR_USER]({ rootState }, payload: any) {
        const res = await DiscountsService.fetchOrderResourceForUser(
            rootState.marketplaceProvider.providerId,
            rootState.marketplaceProvider.defaultMarketPlace.id,
            payload.params
        );
        return res.data;
    },
    async [FETCH_RESOURCE_DETAILS_BY_ID]({ rootState }, payload: any) {
        const res = await DiscountsService.fetchResourceDetailsById(
            payload.orderId,
            payload.orderResourceId,
            payload.orderItemId
        );
        return res.data.data;
    },
    async [ORDER_PLACE]({ rootState }, payload: any) {
        //single api to replace 2 apis
        payload.idempotencyKey = uuidv4();
        const program: any = WebStorage.getItem('program');
        payload.segmentType = 'REWARDS_PRODUCT_OFFERING';
        payload.segmentId = program.rewardOfferingId;
        return new Promise(resolve => {
            PaymentsService.orderPlace(payload)
                .then((res: any) => {
                    resolve({ data: res.data });
                })
                .catch((err: any) => {
                    resolve({ error: err });
                });
        });
    },
    async [ORDER_INITIATE]({ rootState }, payload: any) {
        payload.idempotencyKey = uuidv4();
        const program: any = WebStorage.getItem('program');
        payload.segmentType = 'REWARDS_PRODUCT_OFFERING';
        payload.segmentId = program.rewardOfferingId;
        const res = await PaymentsService.orderInitiate(payload);
        return res.data;
    },
    async [ORDER_INITIATE_PAYMENT]({ rootState }, payload: any) {
        payload.paymentRequestID = uuidv4();
        const res = await PaymentsService.orderInitiatePayment(payload);
        return res.data;
    },
    async [ORDER_CONFIRM]({ rootState }, payload: any) {
        const orderID = payload.orderID;
        payload.confirmOrderRequestId = uuidv4();
        const res = await PaymentsService.orderConfirm(orderID, payload);
        return res.data;
    },
    async [EVALUATE_BASKET]({ rootState, state }, payload: any) {
        payload.requestId = uuidv4();
        if (payload.items && payload.items.length) {
            payload.espId = rootState.marketplaceProvider.providerId;
            payload.marketplaceId =
                rootState.marketplaceProvider.defaultMarketPlace.id;
            const program: any = WebStorage.getItem('program');
            payload.segmentType = 'REWARDS_PRODUCT_OFFERING';
            payload.segmentId = program.rewardOfferingId;

            payload.items.forEach((el: any) => {
                el.denominationCurrencyId = el.denominationCurrency.id;
                el.faceValueCurrencyId = el.denominationCurrency.baseCurrencyId;
                el.denominationValue = Math.ceil(
                    (el.faceValue *
                        el.denominationCurrency.conversionRatio
                            .toBaseConversionDenominator) /
                        el.denominationCurrency.conversionRatio
                            .toBaseConversionNumerator /
                        100
                );
                el.status = 'ENABLED';
            });

            const res = await DiscountsService.evaluateBasket(payload);
            return res.data.data;
        } else {
            return payload;
        }
    },
    async [IS_BASKET_UPDATE_POSSIBLE]({ rootState, state }, payload: any) {
        payload.requestId = uuidv4();
        const program: any = WebStorage.getItem('program');
        payload.segmentType = 'REWARDS_PRODUCT_OFFERING';
        payload.segmentId = program.rewardOfferingId;

        payload.espId = rootState.marketplaceProvider.providerId;
        payload.marketplaceId =
            rootState.marketplaceProvider.defaultMarketPlace.id;

        payload.basketItems.forEach((el: any) => {
            el.denominationCurrencyId = el.denominationCurrency.id;
            el.faceValueCurrencyId = el.denominationCurrency.baseCurrencyId;
            el.denominationValue = Math.ceil(
                (el.faceValue *
                    el.denominationCurrency.conversionRatio
                        .toBaseConversionDenominator) /
                    el.denominationCurrency.conversionRatio
                        .toBaseConversionNumerator /
                    100
            );
        });

        payload.updateItems.forEach((el: any) => {
            el.denominationCurrencyId = el.denominationCurrency.id;
            el.faceValueCurrencyId = el.denominationCurrency.baseCurrencyId;
            el.denominationValue = Math.ceil(
                (el.faceValue *
                    el.denominationCurrency.conversionRatio
                        .toBaseConversionDenominator) /
                    el.denominationCurrency.conversionRatio
                        .toBaseConversionNumerator /
                    100
            );
        });

        const res = await DiscountsService.isBasketUpdatePossible(payload);
        return res.data;
    },

    async [GET_AWARD_DETAILS]({ rootState }, awardId: string) {
        const res = await RecognitionService.getAwardDetails(
            rootState.userCorpDetail,
            awardId
        );

        const award = res.data.data;

        award['timeString'] = dayjs(award.currentState.updatedAt).format(
            'D MMM YYYY'
        );
        award['tags'] = award.criteria.map((tag: string) => {
            return tag;
        });

        if (APPROVED_STATUS.includes(award.status)) {
            award['status'] = 'APPROVED';
        } else if (PENDING_STATUS.includes(award.status)) {
            award['status'] = 'PENDING';
        } else if (DECLINED_STATUS.includes(award.status)) {
            award['status'] = 'DECLINED';
        }

        return award;
    },
};

export default actions;
