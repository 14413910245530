import { UPDATE_BILLING_ADDRESS } from './profile.mutations.types';
import PaymentsService from '@/services/payments.ts';
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { ProfileState } from './profile.types';
import { FETCH_BILLING_ADDRESS } from './profile.actions.types';

const actions: ActionTree<ProfileState, RootState> = {
    async [FETCH_BILLING_ADDRESS]({ rootState, commit }) {
        return new Promise(resolve => {
            PaymentsService.getUserProfile({
                espId: rootState.marketplaceProvider.providerId,
                marketplaceId:
                    rootState.marketplaceProvider.defaultMarketPlace.id,
            }).then(
                (resp: any) => {
                    const billingAddress = [
                        ...resp.data.data.buyerEntity.billingAddresses,
                    ];
                    commit(UPDATE_BILLING_ADDRESS, billingAddress);
                    resolve(billingAddress);
                },
                (err: any) => {
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
};

export default actions;
