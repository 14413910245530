import { RouteConfig } from 'vue-router';
import { MODULES, pages } from '@/commons/constants';

const BenefitsPage = () =>
    import(
        /* webpackChunkName: "BenefitPage" */ '@/modules/benefits/Benefits.vue'
    );
const BenefitsLandingPage = () =>
    import(
        /* webpackChunkName: "BenefitLandingPage" */ '@/modules/benefits/pages/BenefitsLandingPage.vue'
    );

const routes: RouteConfig[] = [
    {
        path: MODULES.BENEFITS.path,
        component: BenefitsPage,
        children: [
            {
                path: '',
                name: pages.BenefitsLandingPage.name,
                component: BenefitsLandingPage,
            },
        ],
    },
];

export default routes;
