import { DiscountsState } from './discounts.types';

const state: DiscountsState = {
    categoryList: [],
    orderDetails: {},
    basket: {
        items: [],
        totalAmount: 0,
        totalDiscount: 0,
        finalAmount: 0,
    },
};

export default state;
