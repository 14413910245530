import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { DiscountsState } from './discounts.types';
import {
    GET_CATEGORY_LIST,
    GET_ORDER_DETAILS,
    GET_BASKET,
} from './discounts.getters.types';

const getters: GetterTree<DiscountsState, RootState> = {
    [GET_CATEGORY_LIST](state: DiscountsState) {
        return state.categoryList;
    },
    [GET_ORDER_DETAILS](state: DiscountsState) {
        return state.orderDetails;
    },
    [GET_BASKET](state: DiscountsState) {
        return state.basket;
    },
};

export default getters;
