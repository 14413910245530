import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { ProfileState } from './profile.types';

import { GET_BILLING_ADDRESS } from './profile.getters.types';

const getters: GetterTree<ProfileState, RootState> = {
    [GET_BILLING_ADDRESS](state: ProfileState) {
        return state.billingAddresses;
    },
};

export default getters;
