import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { RootState } from '@/store/types';
import actions from './profile.actions';
import getters from './profile.getters';
import mutations from './profile.mutations';
import state from './profile.state';

import { ProfileState } from './profile.types';

const module: Module<ProfileState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export const PROFILE_STORE = 'profile';

export const ProfileModule = namespace(PROFILE_STORE);

export default module;
