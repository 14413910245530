import { BenefitState } from './recognition.types';

const state: BenefitState = {
    recievedAwardsData: [],
    nominationsApprovalList: [],
    currentProgramCurreny: {},
    programIdToBadgeList: {},
    categoryList: [],
    orderDetails: {},
    basket: {
        items: [],
        totalAmount: 0,
        totalDiscount: 0,
        finalAmount: 0,
    },
    purchasesData: {},
    nominationsApprovalPendingList: {},
};

export default state;
