import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { RootState } from '@/store/types';
import actions from './discounts.actions';
import getters from './discounts.getters';
import mutations from './discounts.mutations';
import state from './discounts.state';

import { DiscountsState } from './discounts.types';

const module: Module<DiscountsState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export const DISCOUNTS_STORE = 'discounts';

export const DiscountsModule = namespace(DISCOUNTS_STORE);

export default module;
