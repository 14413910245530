
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class MobileLayout extends Vue {
    private navItems = [
        {
            link: '/feed',
            text: 'Feed',
            icon: 'uds-feed-line',
            'icon-selected': 'uds-feed-fill',
        },
        {
            link: '/recognition',
            text: 'Recognition',
            icon: 'uds-recognition-line',
            'icon-selected': 'uds-recognition-fill',
        },
        // {
        //     link: '/discounts',
        //     text: 'Discounts',
        //     icon: 'uds-discount-line',
        //     'icon-selected': 'uds-discount-fill',
        // },
        {
            link: '/more',
            text: 'More',
            icon: 'uds-more-line',
            'icon-selected': 'uds-more-fill',
        },
    ];

    private get basePath() {
        return `/${this.$route.path.split('/')[1]}`;
    }

    private get canShowBottomBar() {
        return (
            this.navItems.filter(item => {
                return (
                    item.link === this.$route.path ||
                    `${item.link}/` === this.$route.path
                );
            }).length > 0
        );
    }
}
