import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import {
    IS_MOBILE_VIEW,
    GET_USER_CORP_DETAILS,
    SHOW_AWARD_POPUP,
    GET_AWARDS_LIST,
    GET_AWARD_BY_ID,
    GET_PROGRAM_LIST,
    GET_COMPANY_ATTRIBUTES,
    GET_MARKETPLACE_PROVIDER,
    GET_PROGRAM_DETAILS_BY_ID,
} from './getters.type';

const getters: GetterTree<RootState, {}> = {
    [IS_MOBILE_VIEW](state: RootState) {
        const { deviceType } = state;
        return deviceType === 'APP' || deviceType === 'MWEB';
    },
    [GET_USER_CORP_DETAILS](state: RootState) {
        return state.userCorpDetail;
    },
    [SHOW_AWARD_POPUP](state: RootState) {
        return state.showAwardPopup;
    },
    [GET_AWARDS_LIST](state: RootState) {
        return state.awardsList;
    },
    [GET_AWARD_BY_ID](state: RootState) {
        return function(id: string) {
            if (!state.awardsList.badgeList) {
                return null;
            }
            return state.awardsList.badgeList.find((award: any) => {
                return award.id == id;
            });
        };
    },
    [GET_PROGRAM_LIST](state: RootState) {
        return state.programList;
    },
    [GET_COMPANY_ATTRIBUTES](state: RootState) {
        return state.companyAttributes;
    },
    [GET_MARKETPLACE_PROVIDER](state: RootState) {
        return state.marketplaceProvider;
    },
    [GET_PROGRAM_DETAILS_BY_ID](state, id: string) {
        return state.programList.find((program: any) => {
            return program.id === id;
        });
    },
};

export default getters;
