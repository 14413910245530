import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { BenefitState } from './recognition.types';
import {
    GET_PROGRAM_CURRENCY,
    GET_PROGRAM_TO_BADGE_LIST,
    GET_CATEGORY_LIST,
    GET_ORDER_DETAILS,
    GET_BASKET,
    GET_RECIEVED_AWARD_BY_USER_ID,
    GET_NOMINATIONS_APPROVAL_PENDING_LIST,
    GET_NOMINATIONS_APPROVAL_PENDING_BY_ID,
    GET_NOMINATIONS_APPROVAL_LIST,
} from './recognition.getters.types';
const getters: GetterTree<BenefitState, RootState> = {
    [GET_NOMINATIONS_APPROVAL_LIST](state: BenefitState) {
        return state.nominationsApprovalList;
    },
    [GET_PROGRAM_CURRENCY](state: BenefitState) {
        return state.currentProgramCurreny;
    },
    [GET_PROGRAM_TO_BADGE_LIST](state: BenefitState) {
        return state.programIdToBadgeList;
    },
    [GET_CATEGORY_LIST](state: BenefitState) {
        return state.categoryList;
    },
    [GET_ORDER_DETAILS](state: BenefitState) {
        return state.orderDetails;
    },
    [GET_BASKET](state: BenefitState) {
        return state.basket;
    },
    [GET_RECIEVED_AWARD_BY_USER_ID](state: BenefitState) {
        // return function(id: string) {
        //     return state.recievedAwardsData.awards.filter((award: any) => {
        //         return award.participantId == id;
        //     });
        // };
        return state.recievedAwardsData;
    },
    [GET_NOMINATIONS_APPROVAL_PENDING_LIST](state: BenefitState) {
        return state.nominationsApprovalPendingList;
    },
    [GET_NOMINATIONS_APPROVAL_PENDING_BY_ID](state: BenefitState) {
        return function(id: string) {
            return state.nominationsApprovalPendingList.find((award: any) => {
                return award.id == id;
            });
        };
    },
};

export default getters;
