import { AxiosInstance, AxiosPromise } from 'axios';
import Axios from 'axios';

import {
    createAxiosInstance,
    ExtendedAxiosRequestConfig,
} from '@zeta/http-client';
import { getEnv } from '@/commons/Utils';
import { setupInterceptorsTo } from '@/commons/interceptor';
//import { MarketplaceServiceTypes } from './types';
// const baseURL: any = {
//     STAGE: 'https://marketplace-spotlight.mum1-stage.zeta.in/marketplace/1.0',
//     PREPROD: 'https://marketplace-spotlight.mum1-pp.zeta.in/marketplace/1.0',
//     PROD: 'https://marketplace-spotlight.mum1.zeta.in/marketplace/1.0',
//     PROD_UK: 'https://sb1-god-spotlight.lon1.zetaapps.in/marketplace/1.0',
//     SWAGGER:
//         'https://virtserver.swaggerhub.com/z3457/spotlight-marketplace/1.0.0-SNAPSHOT',
// };

class DiscountsService {
    private axios: AxiosInstance;

    constructor(config: ExtendedAxiosRequestConfig) {
        this.axios = createAxiosInstance(config);
        setupInterceptorsTo(this.axios);
    }
    public fetch() {
        return this.axios.get('', {});
    }
    public fetchCategories(config: any = {}) {
        return this.axios.request({
            method: 'GET',
            url: '/user/category',
            ...config,
        });
    }
    public fetchSaleItems(queryString = '', config: any = {}) {
        return this.axios.request({
            method: 'GET',
            url: `/user/saleItems${queryString ? '?&' + queryString : ''}`,
            ...config,
        });
    }
    public fetchSaleItemFilters(
        { params, queryString } = { params: {}, queryString: null }
    ) {
        return this.axios.request({
            method: 'GET',
            url: `/user/saleItems/filters${
                queryString ? '?&' + queryString : ''
            }`,
            params: params,
        });
    }
    public fetchSaleItemById(id: number, config: any = {}) {
        return this.axios.request({
            method: 'GET',
            url: `/user/saleItem/${id}`,
            ...config,
        });
    }
    public fetchSubCategoriesById(id: number, config: any = {}) {
        return this.axios.request({
            method: 'GET',
            url: `/user/category/${id}/subcategories`,
            ...config,
        });
    }
    public fetchSaleItemsByCategoryId(id: number, config: any = {}) {
        return this.axios.request({
            method: 'GET',
            url: `/user/saleItems?categoryIds=${id}&&catalogueTypes=DISCOUNT`,
            ...config,
        });
    }

    public fetchOrderResourceForUser(
        espId: number,
        mktpId: number,
        params: any,
        config: any = {}
    ) {
        return this.axios.request({
            method: 'GET',
            url: `/esp/${espId}/marketplace/${mktpId}/user/v1/order/resource`,
            params: params,
            ...config,
        });
    }
    public markOrderResourceAsUsedForUser(
        data: any,
        config: any = {},
        params?: any
    ) {
        const { espId, mktpId, orderId, itemId, resourceId } = config;

        return this.axios.request({
            method: 'PUT',
            url: `/esp/${espId}/marketplace/${mktpId}/user/v1/order/${orderId}/item/${itemId}/resource/${resourceId}/action`,
            params: params,
            ...config,
            data: data,
        });
    }
    public fetchResourceDetailsById(
        orderId: number,
        orderResourceId: number,
        orderItemId: number,
        config: any = {}
    ) {
        return this.axios.request({
            method: 'GET',
            url: `/user/v1/order/${orderId}/item/${orderItemId}/resource/${orderResourceId}`,
            ...config,
        });
    }
    public fetchRefundDetails(pathParams: any, params: any) {
        return this.axios.request({
            method: 'GET',
            url: `/esp/${pathParams.espId}/marketplace/${pathParams.marketplaceId}/user/v1/refund`,
            params: params,
        });
    }

    public evaluateBasket(data: any, config: any = {}) {
        return this.axios.request({
            method: 'POST',
            url: `/esp/${data.espId}/marketplace/${data.marketplaceId}/user/v1/basket/evaluate`,
            ...config,
            data: data,
        });
    }

    public isBasketUpdatePossible(data: any, config: any = {}) {
        return this.axios.request({
            method: 'POST',
            url: `/esp/${data.espId}/marketplace/${data.marketplaceId}/user/v1/basket/update`,
            ...config,
            data: data,
        });
    }

    public fetchOrders(data: any, config: any = {}) {
        return this.axios.request({
            method: 'GET',
            url: `/esp/${data.espId}/marketplace/${data.marketplaceId}/user/v1/orders`,
            ...config,
        });
    }

    public fetchOrderDetails(data: any, orderId: any) {
        return this.axios.request({
            method: 'GET',
            url: `/esp/${data.espId}/marketplace/${data.marketplaceId}/user/v1/order/${orderId}`,
        });
    }

    public fetchMarketplaceProvider(data: any, config: any = {}) {
        return this.axios.request({
            method: 'GET',
            url: `/marketplaceProvider/ESP/${data.espId}`,
        });
    }
}

const config = {
    baseURL: __APP__.MARKETPLACE.BASE_URL,
    resolveAuthToken: () => {
        const token: string = localStorage.getItem('@zeta::authToken') || '';
        return token;
    },
};
export default new DiscountsService(config);
