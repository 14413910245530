import { MutationTree } from 'vuex';
import { DiscountsState } from './discounts.types';
import {
    SET_CATEGORY_LIST,
    SET_ORDER_DETAILS,
    CREATE_BASKET,
    ADD_BASKET_ITEM,
    CHANGE_BASKET_ITEM_QUANTITY,
    REMOVE_BASKET_ITEM,
    RESET_BASKET,
    INITIALIZE_BASKET_FROM_LOCALSTORAGE,
    UPDATE_COMPLETE_BASKET,
} from './discounts.mutations.types';

import WebStorage from '@/commons/web.localStorage.ts';

const mutations: MutationTree<DiscountsState> = {
    [SET_CATEGORY_LIST](state, data: []) {
        state.categoryList = data;
    },
    [SET_ORDER_DETAILS](state, data: {}) {
        state.orderDetails = data;
    },

    [CREATE_BASKET](state, data: {}) {
        state.basket = data;
        WebStorage.setItem('discountsBasket', state.basket);
    },
    [INITIALIZE_BASKET_FROM_LOCALSTORAGE](state) {
        if (WebStorage.hasItem('discountsBasket')) {
            //WebStorage.removeItem('discountsBasket')
            state.basket = WebStorage.getItem('discountsBasket');
        } else {
            state.basket = {
                totalAmount: 0,
                finalAmount: 0,
                items: [],
            };
            WebStorage.setItem('discountsBasket', state.basket);
        }
    },

    [ADD_BASKET_ITEM](state, data: any) {
        state.basket.items.push(data);
        state.basket.totalAmount += data.totalAmount;
        state.basket.finalAmount += data.finalAmount;
        WebStorage.setItem('discountsBasket', state.basket);
    },
    [CHANGE_BASKET_ITEM_QUANTITY](state, data: any) {
        for (let i = 0; i < state.basket.items.length; i += 1) {
            if (
                state.basket.items[i].id == data.id &&
                state.basket.items[i].denominationValue ==
                    data.denominationValue
            ) {
                state.basket.totalAmount -= state.basket.items[i].totalAmount;
                state.basket.finalAmount -= state.basket.items[i].finalAmount;
                state.basket.totalAmount += data.totalAmount;
                state.basket.finalAmount += data.finalAmount;
                state.basket.items[i].quantity = data.quantity;
                state.basket.items[i].totalAmount = data.totalAmount;
                state.basket.items[i].finalAmount = data.finalAmount;
            }
        }
        WebStorage.setItem('discountsBasket', state.basket);
    },
    [REMOVE_BASKET_ITEM](state, data: any) {
        for (let i = 0; i < state.basket.items.length; i += 1) {
            if (
                state.basket.items[i].id == data.id &&
                state.basket.items[i].denominationValue ==
                    data.denominationValue
            ) {
                state.basket.totalAmount -= state.basket.items[i].totalAmount;
                state.basket.finalAmount -= state.basket.items[i].finalAmount;
            }
        }
        state.basket.items = state.basket.items.filter((el: any) => {
            if (
                el.id == data.id &&
                el.denominationValue == data.denominationValue
            ) {
                return false;
            } else {
                return true;
            }
        });
        WebStorage.setItem('discountsBasket', state.basket);
    },

    [RESET_BASKET](state, data: {}) {
        state.basket = {
            items: [],
            totalAmount: 0,
            finalAmount: 0,
            totalDiscount: 0,
        };
        WebStorage.setItem('discountsBasket', state.basket);
    },
    [UPDATE_COMPLETE_BASKET](state, data: any) {
        state.basket = data;
        WebStorage.setItem('discountsBasket', state.basket);
    },
};

export default mutations;
