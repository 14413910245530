export const FETCH_CATEGORY_LIST = 'fetchCategoryList';
export const FETCH_SALEITEMS_LIST = 'fetchSaleItemsList';
export const FETCH_SALEITEM_BY_ID = 'fetchSaleItemById';
export const FETCH_SUBCATEGORY_LIST = 'fetchSubCategoryList';
export const FETCH_SALEITEMS_BY_CATEGORY_ID = 'fetchSaleItemsByCategoryId';
export const FETCH_ORDER_RESOURCE_FOR_USER = 'fetchOrderResourceForUser';
export const FETCH_RESOURCE_DETAILS_BY_ID = 'fetchResourceDetailsById';
export const ORDER_INITIATE = 'orderInitiate';
export const ORDER_INITIATE_PAYMENT = 'orderInitiatePayment';
export const ORDER_CONFIRM = 'orderConfirm';
export const EVALUATE_BASKET = 'evaluateBasket';

export const IS_BASKET_UPDATE_POSSIBLE = 'isBasketUpdatePossible';
export const FETCH_SALEITEMS_FILTERS = 'fetchSaleItemsFilters';

export const FETCH_ORDERS = 'fetchOrders';
export const FETCH_ORDER_DETAILS = 'fetchOrderDetails';
export const FETCH_REFUND_DETAILS = 'fetchRefundDetails';
