import { RouteConfig } from 'vue-router';
import { MODULES, pages } from '@/commons/constants';

const Feed = () =>
    import(/* webpackChunkName: "FeedPage" */ '@/modules/feed/Feed.vue');

const FeedLandingView = () =>
    import(
        /* webpackChunkName: "FeedLandingPage" */ '@/modules/feed/pages/FeedLandingView.vue'
    );
const FeedPinnedView = () =>
    import(
        /* webpackChunkName: "FeedPinnedPage" */ '@/modules/feed/pages/FeedPinnedView.vue'
    );

const AppreciateApplication = () =>
    import(
        /* webpackChunkName: "AppreciateApplicationPage" */ '@/modules/feed/pages/AppreciateApplication.vue'
    );

const IssueAwardDetailsPage = () =>
    import(
        /* webpackChunkName: "IssueAwardDetailsPage" */ '@/modules/feed/pages/award-issuance/IssueAwardDetails.vue'
    );

const IssueNominationAwardDetailsPage = () =>
    import(
        /* webpackChunkName: "IssueNominationAwardDetailsPage" */ '@/modules/feed/pages/award-issuance/IssueNominationAwardDetails.vue'
    );

const SelectEmployeePage = () =>
    import(
        /* webpackChunkName: "SelectEmployeePage" */ '@/modules/feed/pages/award-issuance/SelectEmployee.vue'
    );

const IssueAwardPage = () =>
    import(
        /* webpackChunkName: "IssueAwardPage" */ '@/modules/feed/pages/award-issuance/IssueAward.vue'
    );

const NominationQuestionsPage = () =>
    import(
        /* webpackChunkName: "NominationQuestionsPage" */ '@/modules/feed/pages/award-issuance/NominationQuestions.vue'
    );

const FeedDetailsPage = () =>
    import(
        /* webpackChunkName: "FeedDetailsPage" */ '@/modules/feed/pages/FeedDetails.vue'
    );

const routes: RouteConfig[] = [
    {
        path: MODULES.FEED.path,
        component: Feed,
        children: [
            {
                path: '',
                name: pages.FeedLandingPage.name,
                component: FeedLandingView,
            },
            {
                path: 'appreciate/:badgeId',
                name: pages.AppreciateApplicationPage.name,
                component: AppreciateApplication,
                props: true,
            },
            {
                path: 'issue-award/recognise/:badgeId/:teamHash',
                name: pages.IssueAwardPage.name,
                component: IssueAwardPage,
                props: true,
            },
            {
                path: 'issue-award/nomination/questions/:badgeId/:empHash',
                name: pages.NominationQuestionsPage.name,
                component: NominationQuestionsPage,
                props: true,
            },
            {
                path: 'issue-award/select-employee/:badgeId',
                name: pages.SelectEmployeePage.name,
                component: SelectEmployeePage,
                props: true,
            },
            {
                path: 'issue-award/:badgeId',
                name: pages.IssueAwardDetailsPage.name,
                component: IssueAwardDetailsPage,
                props: true,
            },
            {
                path: 'issue-award/nomination/:badgeId',
                name: pages.IssueNominationAwardDetailsPage.name,
                component: IssueNominationAwardDetailsPage,
                props: true,
            },
            {
                path: 'feed-detail/:id',
                name: pages.FeedDetails.name,
                component: FeedDetailsPage,
                props: true,
            },
        ],
    },
];

export default routes;
