import { RootState } from './types';
import { PROFILE_ITEMS } from '@/commons/constants';

const state: RootState = {
    sidebarItemsList: [],
    sidebarProfileItemsList: [PROFILE_ITEMS.VIEW_PROFILE, PROFILE_ITEMS.LOGOUT],
    userDetail: null,
    noSidebar: false,
    deviceType: 'WEB',
    userCorpDetail: null,
    awardsList: {},
    showAwardPopup: { show: false, type: null },
    programList: [],
    companyAttributes: {},
    userOrderSummary: null,
    marketplaceProvider: {},
};

export default state;
