import { AxiosInstance } from 'axios';
//import { getEnv } from '@/commons/Utils';
import {
    createAxiosInstance,
    ExtendedAxiosRequestConfig,
} from '@zeta/http-client';

// const baseURL: any = {
//     STAGE: 'https://spotlight-rewards-spotlight.mum1-stage.zeta.in',
//     PREPROD: 'https://spotlight-rewards-spotlight.mum1-pp.zeta.in',
//     PROD: 'https://spotlight-rewards-spotlight.mum1.zeta.in',
//     PROD_UK: 'https://sb1-god-spotlight.lon1.zetaapps.in',
// };

// const basePath: any = {
//     STAGE: '/corporate-core/corporate/v1',
//     PREPROD: '/corporate-core/corporate/v1',
//     PROD: '/corporate/v1',
// };
//https://spotlight-rewards-spotlight.mum1-pp.zeta.in/spotlight-rewards/1.0/esp/test_PP_pt_esp_1/corporates/test_PP_pt_corp_1/companies/test_PP_pt_comp_1/rewardOfferings/a19bce47-0a75-4e69-9188-70078d71d57e/accounts/employees/balance
class RewardService {
    private axios: AxiosInstance;
    // private servicePath = basePath[getEnv()];
    constructor(config: ExtendedAxiosRequestConfig) {
        this.axios = createAxiosInstance(config);
    }

    public fetchUserRewardBalance(
        params: any = {},
        rewardOfferingId: string,
        userDetails: any
    ) {
        return this.axios.get(
            `/spotlight-rewards/1.0/tenant/${userDetails.tenantId}/esp/${userDetails.espId}/corporates/${userDetails.corpId}/companies/${userDetails.compId}/rewardOfferings/${rewardOfferingId}/accounts/employees/balance`,
            { params }
        );
    }

    public fetchUserAccountHolderDetails(
        pathParams: any = {},
        params: any = {}
    ) {
        return this.axios.get(
            `/spotlight-rewards/1.0/tenant/${pathParams.tenantId}/accountHolder`,
            {
                params,
            }
        );
    }
}

const config = {
    baseURL: __APP__.REWARDS.BASE_URL,
    resolveAuthToken: () => {
        return localStorage.getItem('@zeta::authToken') || '';
    },
};
export default new RewardService(config);
