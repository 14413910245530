import { RouteConfig } from 'vue-router';
import { MODULES, pages } from '@/commons/constants';

const AuthPages = () =>
    import(/* webpackChunkName: "LoginPage" */ '@/modules/auth/AuthPages.vue');

const ForgotPasswordPage = () =>
    import(
        /* webpackChunkName: "ForgotPasswordPage" */ '@/modules/auth/ForgotPassword.vue'
    );

const LoginPage = () =>
    import(
        /* webpackChunkName: "LoginPage" */ '@/modules/auth/components/Login.vue'
    );

const routes: RouteConfig[] = [
    {
        path: MODULES.AUTH.path,
        component: AuthPages,
        name: pages.Authentication.name,
        children: [
            {
                path: 'login',
                name: pages.LoginPage.name,
                component: LoginPage,
            },
            {
                path: 'forgotPassword',
                name: pages.ForgotPasswordPage.name,
                component: ForgotPasswordPage,
            },
        ],
    },
];

export default routes;
