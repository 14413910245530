import { UserOrderSummaryInterface } from './types';
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import {
    UPDATE_USER_DETAIL,
    UPDATE_USER_CORP_DETAIL,
    UPDATE_PROGRAM_LIST,
    UPDATE_COMPANY_ATTRIBUTES,
    UPDATE_USER_ORDER_SUMMARY,
    SET_MARKETPLACE_PROVIDER,
} from './mutations.types';
import { EventBus, Events } from '@/commons/eventBus';
import {
    FETCH_USER_DETAIL,
    FETCH_PROGRAM_LIST,
    FETCH_COMPANY_ATTRIBUTES,
    FETCH_USER_ORDER_SUMMARY,
    FETCH_ACCOUNT_HOLDER_DETAILS,
    LOG_OUT,
    FETCH_MARKETPLACE_PROVIDER,
    FETCH_BADGE_LIST_BY_EMPLOYEE,
} from './actions.types';
import { config } from '@/commons/constants';
import { getURLParameter } from '@zeta/utils';
import RecognitionService from '@/services/recognition';
import CorpCoreService from '@/services/spotlightcore';
import PaymentsService from '@/services/payments.ts';
import DiscountsService from '@/services/discounts.ts';
import zetaSSC from '@/services/zetaSSC.ts';
import CurrencyConfig from '@/commons/currencyConfig';
import { getFormattedAmount } from '@/commons/Utils';
import RewardService from '@/services/rewards';

const actions: ActionTree<{}, RootState> = {
    async [FETCH_USER_DETAIL]({ rootState, commit }, payload) {
        EventBus.$emit(Events.showLoader);
        return new Promise((resolve, reject) => {
            CorpCoreService.resolveEmployee(payload)
                .then(
                    resp => {
                        EventBus.$emit(Events.hideLoader);
                        let localTenantId = window.localStorage.getItem(
                            'sessionTenantInfo'
                        );

                        if (!localTenantId && rootState.deviceType !== 'APP') {
                            throw new Error('#LOGOUT - Invalid Tenant id ');
                        }
                        const employeeArray = resp.data;
                        if (
                            rootState.deviceType == 'APP' &&
                            getURLParameter('tenantID')
                        ) {
                            localTenantId = getURLParameter('tenantID');
                        }
                        if (employeeArray.length > 0) {
                            let employee: any = !localTenantId
                                ? employeeArray[0]
                                : employeeArray.find(
                                      (emp: any) =>
                                          emp.tenantID == localTenantId
                                  );
                            if (employee.state == 'PAUSED') {
                                throw new Error('#LOGOUT - User is paused');
                                return;
                            }
                            employee = {
                                ...employee,
                                corpId: employee.corpID,
                                espId: employee.espID,
                                compId: employee.companyID,
                                tenantId: employee.tenantID,
                            };

                            commit(UPDATE_USER_CORP_DETAIL, employee);

                            employee.employee.userID =
                                employee.employee.userID ||
                                employee.ssoIdentity;
                            commit(UPDATE_USER_DETAIL, {
                                ...employee.employee,
                                profilePic:
                                    employee.employee.attributes.profilePic,
                            });
                            resolve(employee.employee);
                        } else {
                            throw new Error(
                                '#LOGOUT - User inactive/not found '
                            );
                        }
                    },
                    err => {
                        EventBus.$emit(Events.hideLoader);
                        console.log('###err', err);
                        if (err?.response?.status == 401) {
                            throw new Error(
                                '#LOGOUT - get employee profile throwing 401'
                            );
                        }
                        reject({
                            isError: true,
                            ...err.response,
                        });
                    }
                )
                .catch(reject);
        });
    },

    async [FETCH_ACCOUNT_HOLDER_DETAILS]({ rootState }) {
        EventBus.$emit(Events.showLoader);
        const pathParams = {
            tenantId: rootState.userCorpDetail.tenantId,
        };
        const params = {
            type: 'REAL',
            identifierValue: rootState.userDetail.employeeID,
            identifierProvider: 'CORP_CORE',
            subType: 'EMPLOYEE',
        };
        return new Promise((resolve, reject) => {
            RewardService.fetchUserAccountHolderDetails(
                pathParams,
                params
            ).then(
                resp => {
                    EventBus.$emit(Events.hideLoader);
                    let ahDetails: any = resp.data.data;

                    ahDetails = {
                        ...ahDetails,
                        accountHolderId: ahDetails.auraId,
                    };
                    //  commit(UPDATE_ACCOUNT_HOLDER_DETAILS, ahDetails);
                    resolve(ahDetails);
                },
                err => {
                    EventBus.$emit(Events.hideLoader);
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [FETCH_PROGRAM_LIST]({ rootState, commit }) {
        const { tenantId, espId, corpId, compId } = rootState.userCorpDetail;

        return new Promise(resolve => {
            RecognitionService.fetchProgramList({
                tenantId,
                espId,
                compId,
                corpId,
            }).then(
                (resp: any) => {
                    commit(UPDATE_PROGRAM_LIST, resp.data.data);
                    resolve(resp.data.data);
                },
                (err: any) => {
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
    async [FETCH_COMPANY_ATTRIBUTES]({ rootState, commit }) {
        const { espId, corpId, compId } = rootState.userCorpDetail;
        return new Promise(resolve => {
            zetaSSC
                .getCompanyAttributes({
                    espID: espId,
                    corpID: corpId,
                    compID: compId,
                })
                .then(
                    (res: any) => {
                        commit(
                            UPDATE_COMPANY_ATTRIBUTES,
                            res.data.responseData
                        );
                        resolve(res.data.responseData);
                    },
                    (err: any) => {
                        resolve({
                            isError: true,
                            ...err.toJSON(),
                        });
                    }
                );
        });
    },
    async [FETCH_USER_ORDER_SUMMARY]({ rootState, commit }) {
        return new Promise(resolve => {
            if (rootState.userOrderSummary) {
                resolve(rootState.userOrderSummary);
            } else {
                PaymentsService.getUserOrderStatistics({
                    espId: rootState.marketplaceProvider.providerId,
                    marketplaceId:
                        rootState.marketplaceProvider.defaultMarketPlace.id,
                }).then(
                    (resp: any) => {
                        if (resp.data.data) {
                            const userOrderSummary: any =
                                resp.data.data.userOrderStatistics;

                            const totalSavings =
                                userOrderSummary?.DISCOUNT?.totalSavings;

                            const config: any =
                                CurrencyConfig[
                                    totalSavings.denominationCurrency.name ||
                                        'INR'
                                ];

                            const savedAmount = `${
                                config.symbol
                            }${getFormattedAmount(
                                totalSavings.amount,
                                config
                            )}`;

                            userOrderSummary.DISCOUNT.savedAmount = savedAmount;
                            userOrderSummary.totalVouchersBought =
                                userOrderSummary.common?.totalVouchersBought;

                            commit(UPDATE_USER_ORDER_SUMMARY, {
                                ...userOrderSummary,
                            });
                            resolve({ ...userOrderSummary });
                        }
                    },
                    (err: any) => {
                        resolve({
                            isError: true,
                            ...err.toJSON(),
                        });
                    }
                );
            }
        });
    },
    async [LOG_OUT]({ rootState, commit }) {
        EventBus.$emit(Events.showLoader);
        return new Promise((resolve, reject) => {
            fetch(`${__APP__.CIPHER_PARAMS.baseUrl}/access_token/logout`, {
                method: 'POST',
                headers: {
                    Authorization:
                        'Bearer ' +
                        window.localStorage.getItem('@zeta::authToken'),
                },
            })
                .then(function(res) {
                    EventBus.$emit(Events.hideLoader);
                    // window.localStorage.clear();
                    // window.location.href = '/#/auth/login';
                    if (res.ok) {
                        window.localStorage.clear();
                        window.location.href = '/auth/login';

                        resolve(true);
                    } else {
                        // For now, atleast do a shallow logout if api fails
                        console.log('#error in logout', res);
                        window.localStorage.clear();
                        window.location.href = '/auth/login';
                        reject(false);
                    }
                })
                .catch(function(err) {
                    EventBus.$emit(Events.hideLoader);
                    window.localStorage.clear();
                    window.location.href = '/auth/login';
                    reject(false);
                });
        });
    },
    async [FETCH_MARKETPLACE_PROVIDER]({ rootState, commit }, params) {
        const res = await DiscountsService.fetchMarketplaceProvider(
            rootState.userCorpDetail
        );
        commit(SET_MARKETPLACE_PROVIDER, res.data.data);
        return res.data.data;
    },
    async [FETCH_BADGE_LIST_BY_EMPLOYEE]({ rootState, commit }, params) {
        const { tenantId, espId, corpId, compId } = rootState.userCorpDetail;

        return new Promise(resolve => {
            RecognitionService.fetchBadgeListByEmployee(
                {
                    tenantId,
                    espId,
                    compId,
                    corpId,
                },
                { ...params }
            ).then(
                (resp: any) => {
                    const badgeList = resp.data.data;
                    const visitedProgram: any = {};
                    const programList = badgeList.reduce(
                        (acc: any, badgeDetail: any) => {
                            if (!visitedProgram[badgeDetail.programId]) {
                                acc.push({
                                    id: badgeDetail.programId,
                                    name: badgeDetail.programName,
                                    rewardOfferingId:
                                        badgeDetail.rewardOfferingId,
                                });
                                visitedProgram[badgeDetail.programId] = true;
                            }
                            return acc;
                        },
                        []
                    );
                    commit(UPDATE_PROGRAM_LIST, programList);
                    resolve(resp.data);
                },
                (err: any) => {
                    resolve({
                        isError: true,
                        ...err.toJSON(),
                    });
                }
            );
        });
    },
};

export default actions;
